import { americanExpress, discover, masterCard, visa } from "resources"

export default function CardNetworksRow() {
  return (
    <div className="flex items-center justify-around w-full max-w-lg my-4 pb-4">
      <div>
        <img
          src={visa} alt="visa credit card image" width="53"
          height="30"
        />
      </div>
      <div>
        <img
          src={masterCard} alt="master credit card image" width="53"
          height="30"
        />
      </div>
      <div>
        <img
          src={americanExpress} alt="american express credit card image" width="53"
          height="30"
        />
      </div>
      <div>
        <img
          src={discover} alt="discover credit card image" width="53"
          height="30"
        />
      </div>
    </div>
  )
}