import LoadingSpinner from 'components/loadingSpinner'
import SuspendedSubscriptions from './updateSubscription/SuspendedSubscriptions'
import useFetchPaymentPlans from './api/useFetchPaymentPlans'

export function UpdateExpiredCreditCardController() {

  const { subscriptionsToUpdate, error, loading, message } = useFetchPaymentPlans()

  if ( loading ) return <LoadingSpinner rings={5} width="3rem" height="3rem" />

  if ( message ) return <p data-cy={`update-cc-message-display`}>{message}</p>

  if ( error ) return <p className="text-[red]">{error}</p>

  return (
    <SuspendedSubscriptions
      subscriptionsToUpdate={subscriptionsToUpdate}
    />
  )
}

export default UpdateExpiredCreditCardController