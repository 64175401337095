import { type IPaymentMethod, PaymentProfile, PaymentProfileResponse} from 'types/paymentMethods'
import { uuid4 } from '@sentry/utils'
import { capitalize } from 'lodash'


export function putOrPostUrlPaymentMethod( payMethod: IPaymentMethod | null ) : string {
  const postUrl = `${process.env.REACT_APP_AUTHNET_PAYMENTS_V2_URL}/payment-profile`
  const putUrl = `${process.env.REACT_APP_AUTHNET_PAYMENTS_V2_URL}/payment-profile/${payMethod?.paymentProfileId}`
  if ( !payMethod ) return postUrl
  const { paymentProfileId } = payMethod
  if ( paymentProfileId?.length ) return putUrl

  return postUrl
}

export function serializePaymentProfileResponse( paymentProfileResp : PaymentProfileResponse ) : IPaymentMethod[] {
  return paymentProfileResp?.profile?.paymentProfiles?.map( ( paymentProfile: PaymentProfile ) => {
    const paymentMethodType = paymentProfile?.payment?.creditCard?.cardNumber?.length ? `card` : `ach`
    const { expMonth, expYear } = getExpiration( paymentProfile?.payment?.creditCard?.expirationDate )
    const _payMethod = paymentMethodType === `card` ? {
      cardNumber: paymentProfile?.payment?.creditCard?.cardNumber,
      cardType: paymentProfile?.payment?.creditCard?.cardType || ``,
      expMonth: expMonth,
      expYear: expYear,
      ccv: ``,
      cardholderFirstName: capitalizeCase( paymentProfile?.billTo?.firstName ),
      cardholderLastName: capitalizeCase( paymentProfile?.billTo?.lastName ),
      address: paymentProfile?.billTo?.address,
      city: paymentProfile?.billTo?.city,
      state: paymentProfile?.billTo?.state,
      zip: paymentProfile?.billTo?.zip
    } : {
      routingNumber: paymentProfile?.payment?.bankAccount?.routingNumber,
      accountNumber: paymentProfile?.payment?.bankAccount?.accountNumber,
      nameOnAccount: capitalizeCase( paymentProfile?.payment?.bankAccount?.nameOnAccount || `` ),
      accountType:   paymentProfile?.payment?.bankAccount?.accountType
    }

    return {
      paymentMethodId: uuid4(),
      paymentProfileId: paymentProfile.customerPaymentProfileId,
      paymentMethodType,
      ..._payMethod,
      isEditing: false
    } as IPaymentMethod
  }) ?? []
}

export function getExpiration( expirationDate: string | undefined ) {
  if ( !expirationDate ) return {
    expMonth: ``,
    expYear: ``
  }
  return {
    expMonth: expirationDate.slice( 5, 7 ),
    expYear: expirationDate.slice( 0, 4 )
  }
}

export const capitalizeCase = ( str: string ) => {
  return str.split( ` ` ).map( ( word ) => capitalize( word ) )
    .join( ` ` )
}