import { useState, useRef, RefObject, useMemo } from 'react'
import { getValidationHelper } from '../validation'
import { UpdatePayMethodProps } from '../props'
import LoadingSpinner from "../../loadingSpinner"
import {SelectInput, TextInput} from "../../forms/library"
import { Formatters, getExpiryYearList} from "../../forms/components"
import {months, usaStates} from "../../forms/constants"
import { CardPaymentMethod, UpdateCardPaymentMethod } from 'types/paymentMethods'

export default function UpdateCardMethod({payMethod, onFormSubmit, submitButtonText, onCancel, displayLoading, displayError } : UpdatePayMethodProps ) {
  const [ currentFocus, setCurrentFocus ] = useState( `` )
  const [ updatedCardValues, setUpdatedCardValues ] = useState<UpdateCardPaymentMethod>({
    cardholderFirstName: payMethod.cardholderFirstName,
    cardholderLastName: payMethod.cardholderLastName,
    expMonth: payMethod.expMonth,
    expYear: payMethod.expYear,
    address: payMethod.address,
    city: payMethod.city,
    state: payMethod.state,
    zip: payMethod.zip
  })
  const [ errors, setErrors ] = useState<Record<string, string>>({
    cardholderFirstName: ``,
    cardholderLastName: ``,
    expMonth: ``,
    expYear: ``,
    address: ``,
    city: ``,
    state: ``,
    zip: ``
  })

  const inputRefs = {
    cardholderFirstName: useRef<HTMLInputElement>( null ),
    cardholderLastName: useRef<HTMLInputElement>( null ),
    expMonth: useRef<HTMLSelectElement>( null ),
    expYear: useRef<HTMLSelectElement>( null ),
    address: useRef<HTMLInputElement>( null ),
    city: useRef<HTMLInputElement>( null ),
    state: useRef<HTMLSelectElement>( null ),
    zip: useRef<HTMLInputElement>( null )
  } as Record<string, RefObject<HTMLInputElement | HTMLSelectElement>>

  const evaluateErrors = () => {
    return Boolean( Object.keys( inputRefs ).find( ( fieldName ) => {
      const hasError = errors[fieldName] !== ``
      const isIncomplete = !inputRefs[fieldName]?.current?.value

      return hasError || isIncomplete
    }) )
  }

  const shouldSubmitBeDisabled = useMemo( evaluateErrors, [ errors ] )

  function handleInputChange( event : React.ChangeEvent<HTMLInputElement> ) {
    const { name, value } = event.currentTarget

    setUpdatedCardValues({
      ...updatedCardValues,
      [ name ]: value
    })

    // Check for errors
    const { errorMessage, validator } = getValidationHelper( name )
    const _errors = {
      ...errors
    }
    // If valid set error to empty string
    _errors[ name ] = validator( value ) ? `` : errorMessage
    setErrors( _errors )
  }

  function handleBlur() {
    setCurrentFocus( `` )
  }

  function handleFocus( event : React.ChangeEvent<HTMLInputElement> ) {
    setCurrentFocus( event.target.name )
  }


  const inputHandlers = {
    onChange: handleInputChange,
    onBlur: handleBlur,
    onFocus: handleFocus
  }

  const handleSubmit = ( e : React.FormEvent ) => {
    e.preventDefault()
    const expireYear = getExpiryYearList().find( year => year.YY === updatedCardValues.expYear )
    onFormSubmit && onFormSubmit({
      firstName: updatedCardValues.cardholderFirstName,
      lastName: updatedCardValues.cardholderLastName,
      address: updatedCardValues.address,
      city: updatedCardValues.city,
      state: updatedCardValues.state,
      zip: updatedCardValues.zip,
      expirationDate: `${expireYear?.YYYY ?? updatedCardValues.expYear}-${updatedCardValues.expMonth}`
    })
  }

  return (
    <div className="my-4 px-6 py-2 bg-gray3 rounded-lg">
      <p className="md:text-lg font-bold text-center my-4">{`Update ${payMethod.cardType} Card Ending in ${( payMethod as CardPaymentMethod ).cardNumber?.replaceAll( `-`, `` ).slice( -4 )}`}</p>
      <div className="flex flex-col md:flex-row w-full justify-between gap-4">
        <div className="w-full md:w-1/2">
          <TextInput
            id={`cardholderFirstName`}
            name="cardholderFirstName"
            label="Cardholder's First Name"
            value={updatedCardValues.cardholderFirstName}
            errorMessage={currentFocus !== `cardholderFirstName` ? errors.cardholderFirstName : ``}
            required
            reference={inputRefs.cardholderFirstName as RefObject<HTMLInputElement>}
            {...inputHandlers}
          />
        </div>
        <div className="w-full md:w-1/2">
          <TextInput
            id={`cardholderLastName`}
            name="cardholderLastName"
            label="Cardholder's Last Name"
            value={updatedCardValues.cardholderLastName}
            errorMessage={currentFocus !== `cardholderLastName` ? errors.cardholderLastName : ``}
            reference={inputRefs.cardholderLastName as RefObject<HTMLInputElement>}
            required
            {...inputHandlers}
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row w-full justify-between gap-4">
        <div className="w-full md:w-1/2">
          <SelectInput
            id="expMonth"
            name="expMonth"
            label="Exp. Month"
            value={updatedCardValues.expMonth}
            errorMessage={currentFocus !== `expMonth` ? errors.expMonth : ``}
            reference={inputRefs.expMonth as RefObject<HTMLSelectElement>}
            required
            {...inputHandlers}
          >
            <option value="" hidden>{`Exp. Month`}</option>
            {months.map( month => {
              return <option key={month.value} value={month.value}>{month.text}</option>
            })}
          </SelectInput>
        </div>
        <div className="w-full md:w-1/2">
          <SelectInput
            id="expYear"
            name="expYear"
            label="Exp. Year"
            value={updatedCardValues.expYear?.slice( -2 )}
            errorMessage={currentFocus !== `expYear` ? errors.expYear : ``}
            reference={inputRefs.expYear as RefObject<HTMLSelectElement>}
            required
            {...inputHandlers}
          >
            <option value="" hidden>{`Exp. Year`}</option>
            {getExpiryYearList().map( expiry => {
              return <option key={expiry.YY} value={expiry.YY}>{expiry.YYYY}</option>
            })}
          </SelectInput>
        </div>
      </div>
      <div>
        <TextInput
          id="address"
          name="address"
          label="Address"
          value={updatedCardValues.address}
          errorMessage={currentFocus !== `address` ? errors.address : ``}
          reference={inputRefs.address as RefObject<HTMLInputElement>}
          required
          {...inputHandlers}
        />
      </div>
      <div className="flex flex-col md:flex-row w-full justify-between gap-4">
        <div className="w-full md:w-9/12">
          <TextInput
            id="city"
            name="city"
            label="City"
            value={updatedCardValues.city}
            errorMessage={currentFocus !== `city` ? errors.city : ``}
            reference={inputRefs.city as RefObject<HTMLInputElement>}
            required
            {...inputHandlers}
          />
        </div>
        <div className="w-full md:w-3/12">
          <SelectInput
            id="state"
            name="state"
            label="State"
            value={updatedCardValues.state}
            errorMessage={currentFocus !== `state` ? errors.state : ``}
            formatter={{
              function: Formatters.state
            }}
            reference={inputRefs.state as RefObject<HTMLSelectElement>}
            {...inputHandlers}
            required
          >
            <option value="" hidden>{`State`}</option>
            {usaStates.map( state => {
              return <option key={state.value} value={state.value}>{state.value}</option>
            })}
          </SelectInput>
        </div>
      </div>
      <div className="flex flex-row w-full justify-between gap-4">
        <TextInput
          id="zip"
          name="zip"
          label="Zip"
          type="tel"
          value={updatedCardValues.zip}
          formatter={{
            function: Formatters.zipCode
          }}
          reference={inputRefs.zip as RefObject<HTMLInputElement>}
          required
          errorMessage={currentFocus !== `zip` ? errors.zip : ``}
          {...inputHandlers}
        />
      </div>
      <hr className="my-2"></hr>

      { displayLoading && <div className="my-2"><LoadingSpinner /></div> }

      { displayError && <p className="my-2 text-error">{displayError}</p> }

      <div className="flex flex-col items-center">

        <button
          type="submit"
          className="btn btn-primary w-80"
          disabled={shouldSubmitBeDisabled || displayLoading}
          onClick={handleSubmit}
        >
          {submitButtonText ? submitButtonText : `Save Changes`}
        </button>
        <button
          className="btn btn-secondary w-80"
          disabled={displayLoading}
          onClick={onCancel}
        >
          {`Cancel`}
        </button>
      </div>
    </div>
  )
}