import { afLogo } from "assets/images"
function Logo() {
  return (
    <>
      <img
        src={afLogo}
        alt="aeroflow-health-logo"
        className="object-contain mt-4 px-4 w-2/3 max-w-xl bg-white rounded-lg p-4"
      />
    </>
  )
}

export default Logo