import { OrderItemType } from 'types'
import { useState } from 'react'
import { InfoListItem, DropdownList } from 'components/dropdownList'
import { plusIco, minusIco } from 'assets/images'
import { OrderItemDescriptions as DESC, PatientResponsibilityDescription } from './OrderItemDescriptions'
import InfoTooltip from 'components/infoTooltip'

function OrderItem({ orderItem } : { orderItem : OrderItemType }) {
  const [ dropDownOpen, setDropDownOpen ] = useState( false )

  const formatMoney = ( amount : number ) => {
    return Intl.NumberFormat( `en-US`, {
      style: `currency`,
      currency: `USD`
    }).format( amount )
  }
  const handleDropDownClick = () => { setDropDownOpen( !dropDownOpen ) }

  return (
    <div className="pl-[2rem] mt-3">
      <div className="flex flex-row gap-2 items-center -ml-[20px] cursor-pointer">
        <img src={dropDownOpen ? minusIco : plusIco} alt="plus/minus icon" onClick={handleDropDownClick} />
        <p className="text-primary font-bold text-responsive-lg underline" onClick={handleDropDownClick}>{orderItem.item_description}</p>
        <InfoTooltip title="HCPC Description" description={DESC.hcpc_description} />
      </div>
      <DropdownList isOpen={dropDownOpen}>
        <InfoListItem title="HCPC" value={orderItem.hcpc_code} description={DESC.hcpc_code} />
        <InfoListItem title="Allowed Amount" value={formatMoney( orderItem.insurance_allowed )} description={DESC.insurance_allowed} />
        <InfoListItem title="Insurance Paid" value={formatMoney( orderItem.insurance_paid )} description={DESC.insurance_paid} />
        <InfoListItem title="Patient Responsibility" value={formatMoney( orderItem.amount_owed )} description={<PatientResponsibilityDescription />} />
      </DropdownList>
      <p className="font-bold">{`Qty: ${orderItem.qty}`}</p>
      <p className="font-bold">{`$${orderItem.amount_owed?.toFixed( 2 )}`}</p>
    </div>
  )
}

export default OrderItem