import { uuid4 } from "@sentry/utils"
import { BankPaymentMethod, CardPaymentMethod } from "types/paymentMethods"
import usePayMethodsStore from "../../../store/paymentStore"
import { plusIco, xIco} from "assets/images"
import {PayPalButton } from "modules/merchantPaymentMethods/components/buttons"
import OrPayWithSeparator from "modules/merchantPaymentMethods/components/OrPayWith"
import PayCustomAmountInput from "components/payCustomAmount/input"
import { useState } from "react"
import LoadingSpinner from "components/loadingSpinner"

export const makeEmptyCardMethod = () : CardPaymentMethod => {
  return {
    cardholderFirstName: ``,
    cardholderLastName: ``,
    cardNumber: ``,
    expMonth: ``,
    expYear: ``,
    ccv: ``,
    state: ``,
    city: ``,
    address: ``,
    zip: ``,
    paymentMethodType: `card`,
    paymentMethodId: uuid4(),
    paymentProfileId: null,
    isEditing: true,
    shouldDeleteOnPayment: false
  }
}

export const makeEmptyBankMethod = () : BankPaymentMethod => {
  return {
    routingNumber: ``,
    nameOnAccount: ``,
    accountNumber: ``,
    accountType: `checking`,
    paymentMethodType: `ach`,
    paymentMethodId: uuid4(),
    paymentProfileId: null,
    isEditing: true,
    shouldDeleteOnPayment: false
  }
}

export default function AddNewPayMethod({expanded, setExpanded} : {expanded: boolean, setExpanded: React.Dispatch<React.SetStateAction<boolean>>}) {

  const { addPayMethod, payMethods } = usePayMethodsStore()
  const [ payPalLoading, setPayPalLoading ] = useState<boolean>()

  const handleCancelClick = () => {
    setExpanded( false )
  }

  if ( !expanded && payMethods?.length !== 0 ) return (
    <div className="flex justify-center flex-row gap-2" onClick={() => { return setExpanded( true ) }}>
      <img src={plusIco} width={16} height={16} />
      <button className="text-primary underline">{`Use Different Payment Method`}</button>
    </div>
  )


  return (
    <div className="w-full px-2 bg-gray3 rounded-lg">
      <div className="flex flex-col items-center w-full mx-auto my-2 pb-10 gap-2">

        <div className="pb-4 w-full relative">
          <p className="md:text-lg font-bold text-center">
            {( !payMethods || payMethods.length !== 0 ) && <span>{`Choose Payment Method`}</span>}
            {payMethods?.length !== 0 && (
              <span onClick={handleCancelClick} className="absolute right-0 top-1 cursor-pointer hover:opacity-80">
                <img src={xIco} width={18} height={18} />
              </span>
            )}
          </p>
        </div>
        <OrPayWithSeparator />
        <button
          className="btn-secondary w-80"
          onClick={() => {
            addPayMethod( makeEmptyCardMethod() )
            setExpanded( false )
          }}
          disabled={payPalLoading}
        >
          <p className="py-2 md:text-lg">{`Credit or debit card`}</p>
        </button>
        <button
          className="btn-secondary w-80"
          onClick={() => {
            addPayMethod( makeEmptyBankMethod() )
            setExpanded( false )
          }}
          disabled={payPalLoading}
        >
          <p className="py-2 md:text-lg">{`Bank account`}</p>
        </button>

        <PayPalButton onClick={() => setPayPalLoading( true )} onRequestComplete={() => setPayPalLoading( false )} disabled={payPalLoading} />
        { payPalLoading && (
          <div className="w-full px-2 bg-gray3 rounded-lg">
            <div className="mx-auto max-w-md my-10">
              <LoadingSpinner />
            </div>
          </div>
        )
        }
        <PayCustomAmountInput />
      </div>
    </div>
  )
}