import { useCustomAmountStore } from "./store"
import { TextInput } from "components/forms/library"

export default function PayCustomAmountInput() {

  const [ customAmount, setCustomAmount ] = useCustomAmountStore( ( state ) => ( [ state.customAmount, state.setCustomAmount ] ) )
  const [ shouldUseCustomAmount, setShouldUseCustomAmount ] = useCustomAmountStore( ( state ) => ( [ state.shouldUseCustomAmount, state.setShouldUseCustomAmount ] ) )
  const [ customAmountError, setCustomAmountError ] = useCustomAmountStore( ( state ) => ( [ state.customAmountError, state.setCustomAmountError ] ) )
  const total = useCustomAmountStore( ( state ) => state.total )


  function handleCustomAmountChange( event: React.ChangeEvent<HTMLInputElement> ) {
    const value = event.target.value.replace( `$`, `` ) // remove dollar sign
    const numberValue = Number( value )
    if ( isNaN( numberValue ) ) return // if the value is not a number, do not update the state
    setCustomAmountError( numberValue < 5 ? `Amount must be at least $5` : numberValue > Number( total ) ? `Amount must be less than or equal to the invoice total of $${total}` : `` )

    // remove any digit past the second decimal place and remove any leading zeros
    const newValue = value.replace( /(\.\d{2})\d+$/, `$1` ).replace( /^0+/, `` )
    setCustomAmount( `$${newValue}` ) // add dollar sign
  }

  const handleTogglePress = () => {
    setShouldUseCustomAmount( !shouldUseCustomAmount )
  }

  return (
    <>
      {
        shouldUseCustomAmount && (
          <div className="max-w-xs mx-auto">
            <p className="text-sm mb-2">{`Enter a custom amount to pay`}</p>
            <div className="w-24 mx-auto">
              <TextInput
                className="input text-center"
                value={customAmount}
                onChange={handleCustomAmountChange}
                autoFocus
              />
            </div>
            <p className="text-sm w-48 mx-auto text-error">{customAmountError}</p>
          </div>
        )
      }
      <p className="cursor-pointer underline text-primary" onClick={handleTogglePress}>{`Pay ${shouldUseCustomAmount ? `full` : `custom`} amount`}</p>
    </>
  )
}