import SubscriptionDetail from './SubscriptionDetail'
import { SubscriptionToUpdateInterface } from '../types'

export interface PropsInterface {
  subscriptionsToUpdate: SubscriptionToUpdateInterface[];
}

const SuspendedSubscriptions = ({ subscriptionsToUpdate }: PropsInterface ) => {

  const formattedText = `plan${subscriptionsToUpdate.length > 1 ? `s` : ``}`

  return (
    <div className="sm:max-w-fit md:max-w-2xl lg:max-w-3xl mx-auto w-full">
      <div className="flex flex-col bg-primary p-3 items-center mb-12 sm:rounded-none md:rounded-lg lg:rounded-lg">
        <h4 className="text-white text-xl font-bold text-center">{ `The following payment ${formattedText} have been suspended due to a declined payment.`}</h4>
      </div>
      <>
        {subscriptionsToUpdate?.map( ( subscription : SubscriptionToUpdateInterface ) => {
          return <SubscriptionDetail key={subscription?.invoiceNumber} subscription={subscription} />
        }) }
      </>
    </div>
  )
}

export default SuspendedSubscriptions