import UpdateExpiredCreditCardController from "./UpdateExpiredPaymentController"
import Logo from 'components/logo'


export default function UpdatePaymentPlans() {
  return (
    <div className="flex flex-col items-center gap-2">
      <Logo />
      <UpdateExpiredCreditCardController />
    </div>
  )
}