import MaintenanceListener from "components/maintenanceListener"
import { Route, BrowserRouter as Router, Routes } from "react-router-dom"
import routes from "routes"

function App() {
  return (
    <Router>
      <MaintenanceListener />
      <Routes>
        {routes.map( route => {
          return (
            <Route key={route.path} {...route} />
          )
        })}
      </Routes>
    </Router>
  )
}

export default App