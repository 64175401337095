import { useEffect, useState } from "react"
import { fetchCustomerProfile, fetchSubscriptionsData, fetchSubscriptionsDetail } from './'
import { reportToSentry } from "utils/reportToSentry"
import { type SubscriptionToUpdateInterface, type SubscriptionResponse, type CustomerProfileData, type SubscriptionDetailData } from "../types.d"
import { PaymentProfile } from "types/paymentMethods"

export default function useFetchPaymentPlans() {
  const [ error, setError ] = useState<string | null>( null )
  const [ loading, setLoading ] = useState<boolean>( false )
  const [ subscriptionsToUpdate, setSubscriptionsToUpdate ] = useState<SubscriptionToUpdateInterface[]>( [] )
  const [ message, setMessage ] = useState<string>( `` )
  const [ customerProfileData, setCustomerProfileData ] = useState<CustomerProfileData>({
    customerProfileId: null,
    messages: [],
    profile: null,
    subscriptionIds: []
  })

  const handleFetchSubscriptions = async ( subscriptionIds: string[], paymentProfiles: PaymentProfile[] ) => {
    const controllers = subscriptionIds.map( () => new AbortController() )
    try {
      const subscriptions : SubscriptionResponse[] = await fetchSubscriptionsData( subscriptionIds, controllers )
      const subscriptionDetails = await fetchSubscriptionsDetail()
      // Processing and setting subscriptions to update...
      const updatedSubscriptions = subscriptions?.map( ( subscription : SubscriptionResponse, i: number ) => {
        const associatedSubscriptionDetail = subscriptionDetails?.find( ( sd : SubscriptionDetailData ) => sd?.subscription_id === subscriptionIds[i] )
        let associatedPaymentProfile = paymentProfiles?.find( ( pp ) => pp.customerPaymentProfileId === subscription?.subscription?.profile?.paymentProfile?.customerPaymentProfileId )
        if ( !associatedPaymentProfile ) associatedPaymentProfile = {
          ...subscription?.subscription?.profile?.paymentProfile
        } // Note: this means the payment profile was not found from the customer profile, but the subscription still exists
        const expirationDate = associatedPaymentProfile?.payment?.creditCard?.expirationDate
        const expDate = formatExpirationDate( expirationDate ) // 10-2030 => 10/30
        return {
          subscriptionId: subscriptionIds[i],
          invoiceNumber: subscription?.subscription?.order?.invoiceNumber,
          status: subscription?.subscription?.status,
          amount: subscription?.subscription?.amount,
          name: subscription?.subscription?.name,
          paymentIntervalLength: subscription?.subscription?.paymentSchedule?.interval?.length,
          paymentIntervalUnit: subscription?.subscription?.paymentSchedule?.interval?.unit,
          paymentStartDate: subscription?.subscription?.paymentSchedule?.startDate,
          totalOccurrences: subscription?.subscription?.paymentSchedule?.totalOccurrences,
          expiredCardLastFour: subscription?.subscription?.profile?.paymentProfile?.payment?.creditCard?.cardNumber,
          expiredCardExpirationDate: expDate ?? `xx/xx`,
          associatedPaymentProfile,
          dollarRemaining: associatedSubscriptionDetail?.dollar_remaining,
          initialPayments: associatedSubscriptionDetail?.initial_payments,
          lastDeclinedDate: associatedSubscriptionDetail?.last_declined_date,
          monthlyPaymentAmount: associatedSubscriptionDetail?.monthly_payment_amount,
          paymentsCompleted: associatedSubscriptionDetail?.payments_completed,
          paymentsDeclined: associatedSubscriptionDetail?.payments_declined,
          paymentsRemaining: associatedSubscriptionDetail?.payments_remaining,
          planStartDate: associatedSubscriptionDetail?.plan_start_date
        } as SubscriptionToUpdateInterface
      })?.filter( ( s: SubscriptionToUpdateInterface ) => {
        const { status } = s
        // @NOTE IMPORTANT  to test this feature you may want to spoof suspended subscriptions, switch out the status === `active` to test in that case
        if ( process.env.REACT_APP_ENVIRONMENT !== `production` ) return status === `active` || status === `suspended` || status === `expired`
        return status === `suspended`
      }) || []
      setSubscriptionsToUpdate( updatedSubscriptions )
      setMessage( updatedSubscriptions.length > 0 ? `` : `No action required.` )
    } catch ( err: unknown ) {
      console.error( err )
      reportToSentry( new Error( `Subscription data fetch error` ) )
      setError( `${err ?? `An Unknown Error Fetching Subscriptions Occurred`}` )
    } finally {
      setLoading( false )
    }
    controllers.forEach( c => c.abort() )
  }

  useEffect( () => {
    const controller = new AbortController()
    setLoading( true )

    fetchCustomerProfile( controller.signal )
      .then( ( response: CustomerProfileData ) => {
        if ( Number( response?.profile?.paymentProfiles?.length ) >= 10 ) {
          throw new Error( `Maximum number of saved payment methods reached.` )
        }
        setCustomerProfileData( response )
        return {
          subscriptionIds: response.subscriptionIds || [],
          paymentProfiles: response.profile?.paymentProfiles || []
        }
      })
      .then( ( customerProfileData ) => {
        if ( customerProfileData?.subscriptionIds?.length > 0 ) {
          return handleFetchSubscriptions( customerProfileData.subscriptionIds, customerProfileData.paymentProfiles )
        }
        setMessage( `No subscriptions to update.` )
      })
      .catch( ( err: unknown | Error ) => {
        setError( ( err as Error )?.message || `Error during data fetching.` )
      })
      .finally( () => {
        setLoading( false )
        controller.abort()
      })

    return () => controller.abort()
  }, [] )

  return {
    subscriptionsToUpdate,
    error,
    loading,
    customerProfileData,
    message
  }
}

const formatExpirationDate = ( expirationDate: string | undefined ) => {
  if ( !expirationDate?.length ) return `xx/xx`
  const parts = expirationDate.split( `-` ).reverse()
  if ( parts.length !== 2 ) return `xx/xx`
  return `${parts[0]}/${parts[1].substring( 2 )}`
}