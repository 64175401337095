import { ViewPayMethodPropsNoSave } from "../props"
import { BankPaymentMethod, CardPaymentMethod } from "types/paymentMethods"
import { checkIco, circleIco } from 'assets/images'
import LoadingSpinner from "../../loadingSpinner"
import { capitalize } from "lodash"
import { ReactElement } from "react"
import CreditCardIcon from '@mui/icons-material/CreditCard'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import EditIcon from '@mui/icons-material/Edit'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

export default function ViewPayMethod({payMethod, setUpdatePayMethod, handleDelete, toggleSelect, isSelected, displayError, displayLoading} : ViewPayMethodPropsNoSave ) {
  if ( !payMethod ) return null

  const { paymentMethodType } = payMethod

  if ( displayLoading ) return <div className="my-2"><LoadingSpinner /></div>

  return (
    <>
      { displayError && <p className="my-2 text-error">{displayError}</p> }

      <div className="flex flex-row items-center justify-between my-2 p-2 px-2 bg-gray3 rounded-lg cursor-pointer" onClick={toggleSelect}>

        <div
          className="hover:opacity-80 p-2"
        >
          {
            isSelected ? (
              <img
                src={checkIco}
                alt="check mark"
                width={24}
                height={24}
              />
            ) : (
              <img
                src={circleIco}
                alt="no check mark"
                width={24}
                height={24}
              />
            )
          }
        </div>

        { paymentMethodType === `ach` ? ( // we show bank
          <BankInfoRow payMethod={payMethod as BankPaymentMethod} />
        ) : ( // else we show card
          <CardInfoRow payMethod={payMethod as CardPaymentMethod} />
        )}
        <div className="flex gap-0.5 items-center justify-center">
          {
            paymentMethodType === `card` ? (
              <button
                className="hover:opacity-80"
                onClick={() => {
                  setUpdatePayMethod( Object.assign({}, payMethod ) )
                }}
              >
                <EditIcon className="hover:opacity-80 text-primary" />
              </button>
            ) : <div className="w-6"></div>
          }

          <button
            onClick={handleDelete}
          >
            <DeleteForeverIcon className="hover:opacity-80 text-red-800" />
          </button>
        </div>

      </div>
    </>
  )
}
export const CardInfoRow = ({payMethod}: {payMethod : CardPaymentMethod}) => {
  return <>
    <InfoRow title={`${capitalize( payMethod?.cardType )} Card `} value={`Ending in ${( payMethod as CardPaymentMethod ).cardNumber?.replaceAll( `-`, `` ).slice( -4 )}`} />
    <InfoRow title={`Name on Card`} value={`${payMethod.cardholderFirstName} ${payMethod.cardholderLastName}`} />
    <InfoRow title={`Expiration`} value={`${payMethod?.expMonth}/${payMethod?.expYear}`} />
    <InfoRow title={`Method`}><CreditCardIcon /></InfoRow>
  </>
}

export const BankInfoRow = ({payMethod}: {payMethod : BankPaymentMethod}) => {
  return <>
    <InfoRow title={`${capitalize( payMethod.accountType )} Account`} value={`Ending in ${( payMethod as BankPaymentMethod ).accountNumber?.slice( -4 )}`} />
    <InfoRow title="Name on Account" value={`${ payMethod.nameOnAccount }`} />
    <p className="w-1/5"></p>
    <InfoRow title={`Method`}><AccountBalanceIcon /></InfoRow>
  </>
}

export const InfoRow = ({title, value, children } : {title: string, value?: string, children?: ReactElement }) => {
  if ( children ) return <div className="w-1/5"><p className="text-xs">{title}</p>{children}</div>

  return <div className="w-1/5"><p className="text-xs">{title}</p><p>{value}</p></div>
}