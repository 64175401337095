import { caratLeft } from "resources"
import {PaymentMethodsBlock} from "../../components/payments"

type PaymentProps = { onBackPress? : () => void, total: string }
function Payment({ onBackPress, total }: PaymentProps ) {

  function handleBack() {
    onBackPress && onBackPress()
  }

  return (
    <div className="w-full max-w-2xl p-4">
      {onBackPress && <img
        src={caratLeft}
        onClick={handleBack}
        alt="Go Back"
        width="10"
        height="10"
        className="cursor-pointer"
      />}
      <PaymentMethodsBlock blockId={`payment`} total={total} />
    </div>
  )
}

export default Payment