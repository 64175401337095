import { getHashFromUrl } from 'utils/functions'

export default async function fetchSubscriptionsData( subscriptionIds, controllers ){

  const fetchData = async ( id, i ) => {
    const response = await fetch( `${process.env.REACT_APP_AUTHNET_PAYMENTS_URL}/v2/recurring-charge/${id}`, {
      headers:{
        "Authorization": `PatientHash ${getHashFromUrl()}`
      },
      signal: controllers[i].signal
    })

    return await response.json()
  }

  const subscriptionFetches = []

  subscriptionIds.forEach( ( subId, i ) => {
    subscriptionFetches.push( fetchData( subId, i ) )
  })

  return Promise.all( subscriptionFetches )
}