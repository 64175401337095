import { getHashFromUrl } from "utils"

export const GENERIC_ERROR_MESSAGE = `An error occurred paying with PayPal. Please try again or use a different payment method.`

export async function requestPayPalAuth( amount : string, lineItems? : AuthNetLineItem[] ) {

  const response = await fetch( `${process.env.REACT_APP_AUTHNET_PAYMENTS_URL}/paypal-auth-capture`, {
    method: `POST`,
    headers: {
      'Content-Type': `application/json`,
      Authorization: `PatientHash ${getHashFromUrl()}`
    },
    body: JSON.stringify({
      amount,
      ...( !lineItems?.length ? {} : {
        ...convertLineItemsToAuthNetFormat( lineItems )
      })
    })

  })
  if ( !response || !response?.ok ) throw new Error( GENERIC_ERROR_MESSAGE )
  const {data: responseData} = await response.json().catch( error => {
    console.error( `There was an error parsing the response from the server`, error )
  })
  if ( responseData?.messages?.resultCode === `Error` ) throw new Error( GENERIC_ERROR_MESSAGE )

  const { secureAcceptance, transId } = responseData.transactionResponse
  return {
    payPalRedirectUrl: secureAcceptance.SecureAcceptanceUrl,
    refTransId: transId
  }
}

export type AuthNetLineItem = {
  itemId: string;
  name: string;
  quantity: number;
  unitPrice: number;
}

function convertLineItemsToAuthNetFormat( lineItems : AuthNetLineItem[] ) {
  return lineItems.reduce( ( acc : any, curr ) => {
    // Authnet required the line item key to be called lineItem, there is no array support. I know this looks ridiculous but it is what it is.
    acc.lineItems.lineItem = {
      "itemId": curr.itemId,
      "name": curr.name,
      "quantity": curr.quantity,
      "unitPrice": curr.unitPrice
    }
    return acc
  }, {
    lineItems: {}
  })
}