export const defaultPhoneNumber = `8665650548`
export const billingEmail = `SleepBillinginquires@aeroflowinc.com`
export const responsibilityMessage = `the total amount due represents the patient responsibility after insurance adjustments and payments. `
export const paymentMessage = `Payments by check will be converted into electronic fund transfers and funds may be debited from your account as soon as the same day payment is received.`

export const phoneFormatter = ( phoneNumber : string ) => {
  const cleaned = ( String( phoneNumber ) ).replace( /\D/g, `` )
  const match = cleaned.match( /^(1|)?(\d{3})(\d{3})(\d{4})$/ )
  if ( match ) {
    return [ `(`, match[2], `) `, match[3], `-`, match[4] ].join( `` )
  }

  return phoneNumber
}