import { requestPayPalAuth, GENERIC_ERROR_MESSAGE } from "../api/paypal"
import * as React from 'react'
import { PayPalLogo } from "assets/images"
import { useCustomAmountStore } from "components/payCustomAmount/store"
import { useDigitalStatementStore } from "store"
import { type OrderItemType as OrderItem } from "types"
import { reportToSentry } from "utils/reportToSentry"

export function PayPalButton({onClick, onRequestComplete, disabled}: {onClick?: () => void, onRequestComplete?: ( error: string ) => void, disabled?: boolean}) {

  const { userDefinedTotal, isNotValidUserDefinedTotal } = useCustomAmountStore( ( state ) => ({
    userDefinedTotal: state.getUserDefinedTotal(),
    isNotValidUserDefinedTotal: state.getIsNotValidUserDefinedTotal()
  }) )
  const { digitalStatementData } = useDigitalStatementStore()

  const [ errorMessage, setErrorMessage ] = React.useState<string>( `` )

  async function handlePayPalClick() {
    onClick && onClick()
    if ( disabled ) return

    const amount = userDefinedTotal.replace( `$`, `` )
    const lineItems = digitalStatementData?.items?.[0]?.items?.map( ( item : OrderItem ) => ({
      itemId: item.hcpc_code,
      name: `DME_ID: ${item.hcpc_code}`,
      quantity: item.qty,
      unitPrice: item.amount_owed
    }) ) || []

    const requestPayPalAuthResponse = await requestPayPalAuth( amount, lineItems ).catch( error => {
      reportToSentry( error?.message || `Unknown PayPal Initial Request Error` )
      setErrorMessage( error?.message || GENERIC_ERROR_MESSAGE )
    }) as { payPalRedirectUrl: string | undefined, refTransId: string | undefined}

    if ( requestPayPalAuthResponse?.payPalRedirectUrl && requestPayPalAuthResponse?.refTransId ) {
      sessionStorage.setItem( `refTransId`, requestPayPalAuthResponse?.refTransId )
      window.location.href = requestPayPalAuthResponse?.payPalRedirectUrl
      return
    }

    onRequestComplete && onRequestComplete( GENERIC_ERROR_MESSAGE )
    setErrorMessage( GENERIC_ERROR_MESSAGE )
  }

  return (
    <div>
      <button
        className="my-2 p-4 max-w-xs w-80 rounded-sm bg-[#ffc439] hover:opacity-80 disabled:opacity-20 disabled:cursor-not-allowed"
        type="button"
        onClick={handlePayPalClick}
        disabled={disabled || isNotValidUserDefinedTotal}
      >
        <img className="h-5 mx-auto" src={PayPalLogo} height={20} />
      </button>
      { errorMessage && <p className="text-error max-w-xs">{ errorMessage }</p> }
    </div>
  )
}
