import { IPaymentMethod } from 'types/paymentMethods.d'
import { serializePaymentMethod } from './utils'
import {getHashFromUrl} from 'utils/functions'
import {reportToSentry} from 'utils/reportToSentry'

export const getPaymentProfiles = async ( signal: AbortSignal ) => {
  const hash = getHashFromUrl()

  // TODO: right now this function is not being used but could be used in the future going to leave it here for now if used in the future throw new error
  if ( !hash ) reportToSentry( new Error( `No hash in url for digital patient statements` ) )

  const auth = `PatientHash ${hash}`
  const response = await fetch( `${process.env.REACT_APP_AUTHNET_PAYMENTS_URL}/v2/profile`, {
    headers: {
      'Authorization': auth,
      'Content-Type': `application/json`,
      'Access-Control-Allow-Origin': `*`
    },
    signal
  })

  return await response.json()
}


/**
 * Makes a payment with payment method detials attached to the body
 */
export const charge = async ( paymentDetails: any, isSubscription: boolean ) => {
  const hash = getHashFromUrl()
  const auth = `PatientHash ${hash}`

  const requestOperation = isSubscription ? `recurring-charge` : `charge`

  const API_PATH = ( paymentDetails?.paymentProfileId ) ? `/v2/payment-profile/${paymentDetails.paymentProfileId}/${requestOperation}` : `/v2/${requestOperation}`

  const body = JSON.stringify( paymentDetails )
  const response = await fetch( `${process.env.REACT_APP_AUTHNET_PAYMENTS_URL}${API_PATH}`, {
    method: `POST`,
    headers: {
      Authorization: auth,
      "Content-Type": `application/json`
    },
    body: body
  })
    .catch( error => {
      reportToSentry( new Error( `Failed to make payment` ), {
        cause: error
      })
    })

  const paymentData = await response?.json()

  const authnet_message = paymentData?.messages?.message?.length ? paymentData?.messages?.message[0]?.text : ``
  const chargeResponse = paymentData?.transactionResponse.responseCode

  let chargeResponseMessage = `Successful.`

  // chargeResponse === `1` is a successful charge
  // chargeResponse === `2` is a declined charge
  // chargeResponse === `3` is an error
  // chargeResponse === `4` is a held for review charge
  if ( chargeResponse === `2` || chargeResponse === `3` ) {
    reportToSentry( new Error( `Failed to make payment` ), {
      authnet_message,
      authnet_response: JSON.stringify( paymentData ?? `Authnet Response is undefined` ),
      chargeResponse
    })

    chargeResponseMessage = `Failed to make payment.`
  }

  if ( chargeResponse === `4` ) {
    reportToSentry( new Error( `Payment is on hold` ), {
      authnet_message,
      authnet_response: JSON.stringify( paymentData ?? `Authnet Response is payment held for review` )
    })
  }

  return {
    paymentData,
    chargeResponseMessage
  }
}

export const submitPaymentMethod = async ( paymentMethod: IPaymentMethod ) => {
  const hash = getHashFromUrl()
  const auth = `PatientHash ${hash}`
  const paymentMethodBody = serializePaymentMethod( paymentMethod )

  const response = await fetch( `${process.env.REACT_APP_AUTHNET_PAYMENTS_URL}/v2/payment-profile`, {
    method: `POST`,
    headers: {
      Authorization: auth,
      "Content-Type": `application/json`
    },
    body: JSON.stringify( paymentMethodBody )
  })

  return await response.json()
}

export const deletePaymentMethod = async ( paymentProfileId: string ) => {

  const hash = getHashFromUrl()
  const auth = `PatientHash ${hash}`

  const response = await fetch( `${process.env.REACT_APP_AUTHNET_PAYMENTS_URL}/v2/payment-profile/${paymentProfileId}`, {
    method: `DELETE`,
    headers: {
      Authorization: auth
    }
  })

  return await response.json()
}