import React, { useEffect } from 'react'

function SurveyMonkey(): JSX.Element {
  useEffect( () => {
    const timer = setTimeout( () => {
      const el = document.createElement( `script` )
      el.src = `https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgdyPNSHvvL3eLDydr27zAN2_2Fmjid3iQS2Iu2_2FAlykDY_2FU.js`
      document.body.appendChild( el )
    }, 2000 )

    return () => {
      clearTimeout( timer )
    }
  }, [] )

  return (
    <div id="smcx-sdk"></div>
  )
}

export default SurveyMonkey