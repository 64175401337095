import InfoTooltip from "components/infoTooltip"

export interface InfoListItemProps {
  title: string;
  value: string | number;
  description: string | JSX.Element;
}

export default function InfoListItem({title, value, description}: InfoListItemProps ) {
  return (
    <li className="flex flex-row items-center gap-2 my-2">
      <InfoTooltip title={title} description={description} icoSize="sm" />
      <p className="font-bold text-responsive-sm">{`${title}:`}</p>
      <p>{value}</p>
    </li>
  )
}