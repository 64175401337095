import { checkIco } from "assets/images"

export default function Success({message} : {message: string}) {
  return (
    <div className="flex flex-col justify-center items-center my-4 gap-2">
      <img
        src={checkIco}
        width="96"
        height="96"
        alt={`check mark`}
      />
      <p></p>
      <p className="text-center max-w-xl">{message}</p>
    </div>
  )
}