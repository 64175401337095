import { quizIco } from "assets/images"

export default function FaqLink() {
  return (
    <a
      href="https://aeroflowhealth.com/faq/#billing"
      target="_blank"
      rel="noreferrer"
      className="flex justify-center gap-2 my-5 w-fit mx-auto"
    >
      <img
        src={quizIco}
        alt={`question icon img`}
        width={25}
      />
      <span>{`Frequently Asked Questions`}</span>
    </a>
  )
}