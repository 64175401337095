import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import Logo from "components/logo"


const colors = {
  cobalt1: `#0057a8`,
  cobalt2: `rgba(0, 87, 168, 0.8)`,
  cobalt3: `rgba(0, 87, 168, 0.6)`,
  cobalt4: `rgba(0, 87, 168, 0.2)`,
  cobalt5: `#f4f6fa`,
  cobalt6: `rgba(0, 87, 168, 0.05);`,
  midnight1: `#022859`,
  midnight2: `#6d7f9a`,
  midnight3: `#e0e5eb`
}

const width = `5rem`

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const StyledSpinner = styled.div`
  --spinner-color: ${colors.cobalt5};
  --spinner-color-top: ${colors.cobalt1};
  --spinner-2-color: ${colors.midnight1};
  --spinner-3-color: ${colors.cobalt5};
  --spinner-3-color-top: ${colors.cobalt2};
  --spinner-4-color-top: ${colors.cobalt1};
  --spinner-5-color: ${colors.cobalt2};
  --spinner-6-color: ${colors.cobalt5};
  --spinner-7-color: ${colors.midnight1};

  --spinner-width: ${width};

  animation: ${spin} 2s linear infinite;
  bottom: 0;
  border: calc(var(--spinner-width) * 0.055) solid var(--spinner-color);
  border-top-color: var(--spinner-color-top);
  border-radius: 100%;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;

  &:before, &:after {
    content: '';
    background-color: var(--spinner-color-top);
    border-radius: 50%;
    height: 5%;
    width: 5%;
    position: absolute;
    top: 15%;
  }

  &:before {
    left: 13%;
  }

  &:after {
    right: 13%;
  }

  &.two {
    border-color: var(--spinner-2-color);
    border-width: calc(var(--spinner-width) * 0.031);
    height: 87%;
    width: 87%;
    opacity: 0.22;
    &:before, &:after {
      display: none;
    }
  }

  &.three {
    animation: ${spin} reverse 4s linear infinite;
    border-color: var(--spinner-3-color);
    border-top-color: var(--spinner-3-color-top);
    border-width: calc(var(--spinner-width) * 0.031);
    height: 76%;
    width: 76%;

    &:before, &:after {
      background-color: var(--spinner-3-color-top);
      height: 3%;
      width: 3%;
      top: 10%;
    }

    &:before {
      left: 8%;
    }

    &:after {
      right: 8%;
    }
  }

  &.four {
    animation: ${spin} 1.25s linear infinite;
    border-color: rgba(255, 255, 255, 0);
    border-top-color: var(--spinner-4-color-top);
    border-width: calc(var(--spinner-width) * 0.031);
    height: 58%;
    width: 58%;
    z-index: 9;

    &:before, &:after {
      background-color: var(--spinner-4-color-top);
      height: 3%;
      width: 3%;
      top: 6%;
    }

    &:before {
      left: 5.5%;
    }

    &:after {
      right: 5.5%;
    }
  }

  &.five {
    animation: ${spin} reverse 1.25s linear infinite;
    border-color: var(--spinner-5-color);
    border-top-color: rgba(255, 255, 255, 0);
    border-width: calc(var(--spinner-width) * 0.016);
    height: 58%;
    width: 58%;
    &:before, &:after {
      display: none;
    }
  }

  &.six {
    border-color: var(--spinner-6-color);
    border-width: calc(var(--spinner-width) * 0.024);
    height: 50%;
    width: 50%;
    &:before, &:after {
      display: none;
    }
  }

  &.seven {
    border-color: var(--spinner-7-color);
    border-width: calc(var(--spinner-width) * 0.008);
    height: 50%;
    width: 50%;
    &:before, &:after {
      display: none;
    }
  }
`

const LoadingSpinner = ({ width = `5rem`, height = `5rem`}) => {
  const style = {
    width,
    height
  }

  const spinners = [
    <StyledSpinner key={0} className="absolute top-0 left-0" width={width} />,
    <StyledSpinner key={1} className="absolute top-0 left-0 two" width={width} />,
    <StyledSpinner key={2} className="absolute top-0 left-0 three" width={width} />,
    <StyledSpinner key={3} className="absolute top-0 left-0 four" width={width} />,
    <StyledSpinner key={4} className="absolute top-0 left-0 five" width={width} />,
    <StyledSpinner key={5} className="absolute top-0 left-0 six" width={width} />,
    <StyledSpinner key={6} className="absolute top-0 left-0 seven" width={width} />
  ]

  return (
    <div className="relative mx-auto my-2" style={style}>
      {spinners.map( ( Spinner ) => {
        return Spinner
      })}
    </div>
  )
}
LoadingSpinner.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  rings: PropTypes.number
}

export default LoadingSpinner

export function LoadingPage() {
  return (
    <>
      <div className="flex justify-center items-center bg-white rounded-lg w-screen md:mb-20 max-w-7xl px-4">
        <Logo />
      </div>
      <div className="mx-auto my-auto">
        <LoadingSpinner />
      </div>
    </>
  )
}
