// import { regexErrors } from 'components/inputs/constants'
// import { Validators } from 'components/inputs/components'
/* Util for error handling */

import {Validators} from "../forms/components"
import {regexErrors} from "../forms/constants"

/** payment Method Field validation helper
 *  returns a validator function and an error message string : { validator: func, errorMessage: string }
 */
export function getValidationHelper( name ) {
  const validators = {
    cardNumber: {
      validator: Validators.creditCard,
      errorMessage: regexErrors.card_number
    },
    cardholderFirstName: {
      validator: Validators.name,
      errorMessage: regexErrors.first_name
    },
    cardholderLastName: {
      validator: Validators.name,
      errorMessage: regexErrors.last_name
    },
    expMonth: {
      validator: Validators.expiryMonth,
      errorMessage: regexErrors.expmm
    },
    expYear: {
      validator: Validators.expiryYear,
      errorMessage: regexErrors.expyyyy
    },
    address: {
      validator: Validators.street,
      errorMessage: regexErrors.address
    },
    city: {
      validator: Validators.city,
      errorMessage: regexErrors.city
    },
    state: {
      validator: Validators.state,
      errorMessage: regexErrors.state
    },
    zip: {
      validator: Validators.zipCode,
      errorMessage: regexErrors.zip
    },
    ccv: {
      validator: Validators.ccv,
      errorMessage: regexErrors.ccv
    },
    accountNumber: {
      validator: Validators.accountNumber,
      errorMessage: regexErrors.accountNumber
    },
    routingNumber: {
      validator: Validators.routingNumber,
      errorMessage: regexErrors.routingNumber
    },
    nameOnAccount: {
      validator: Validators.name,
      errorMessage: regexErrors.name
    }
  }

  return validators[name]
}
