import {IPaymentMethod, PaymentProfile, PaymentProfileResponse} from "../types/paymentMethods"
import { uuid4 } from '@sentry/utils'

export function getHashFromUrl(){
  const urlParams = new URLSearchParams( window.location.search )
  const patientHash = urlParams.get( `sgh` ) ?? urlParams.get( `txt` )

  return patientHash
}

export function serializePaymentProfileResponse( paymentProfileResp : PaymentProfileResponse ) : IPaymentMethod[] {
  return paymentProfileResp?.profile?.paymentProfiles?.map( ( paymentProfile: PaymentProfile ) => {
    const paymentMethodType = paymentProfile?.payment?.creditCard?.cardNumber?.length ? `card` : `ach`
    const _payMethod = paymentMethodType === `card` ? {
      cardNumber: paymentProfile?.payment?.creditCard?.cardNumber,
      expMonth: ``,
      expYear: ``,
      ccv: ``,
      cardholderFirstName: paymentProfile?.billTo?.firstName,
      cardholderLastName: paymentProfile?.billTo?.lastName,
      address: paymentProfile?.billTo?.address,
      city: paymentProfile?.billTo?.city,
      state: paymentProfile?.billTo?.state,
      zip: paymentProfile?.billTo?.zip
    } : {
      routingNumber: paymentProfile?.payment?.bankAccount?.routingNumber,
      accountNumber: paymentProfile?.payment?.bankAccount?.accountNumber,
      nameOnAccount: paymentProfile?.payment?.bankAccount?.nameOnAccount
    }

    return {
      paymentMethodId: uuid4(),
      paymentProfileId: paymentProfile.customerPaymentProfileId,
      paymentMethodType,
      ..._payMethod,
      isEditing: false
    } as IPaymentMethod
  }) ?? []
}


export const checkMaintenanceFlag = async () => {
  const response = await fetch( `${process.env.REACT_APP_SITE_MAINTENANCE_URL}/get-site-status?site=${encodeURI( `bill pay` )}` )

  return await response.json()
}
