import { Modal as BaseModal, Fade, styled, css } from '@mui/material'
import React from 'react'

function CustomModal({ open, setOpen, children } : { open: boolean, setOpen: ( _open: boolean ) => void, children: React.ReactNode }) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => setOpen( false )}
      closeAfterTransition
      slots={{
        backdrop: StyledBackdrop
      }}
    >
      <Fade in={open}>
        <ModalContent sx={style}>
          {children}
        </ModalContent>
      </Fade>
    </Modal>
  )
}

// eslint-disable-next-line react/display-name
const Backdrop = React.forwardRef<HTMLDivElement, { open?: boolean }>(
  ( props, ref ) => {
    const { open, ...other } = props
    return (
      <Fade in={open}>
        <div ref={ref} {...other} />
      </Fade>
    )
  }
)

const Modal = styled( BaseModal )`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledBackdrop = styled( Backdrop )`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`

const style = {
  position: `absolute` as const,
  top: `50%`,
  left: `50%`,
  transform: `translate(-50%, -50%)`
}

const ModalContent = styled( `div` )(
  () => css`
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px hsl(0deg 0% 0% / 0.3);
    padding: 25px 50px;
    color: black;

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: black;
      margin-bottom: 4px;
    }
  `
)

export default CustomModal