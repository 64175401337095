import { useParams } from "react-router-dom"
import FailurePage from "./failure"
import PayPalAuthorized from "./success"

export default function PayPalRedirectLanding() {
  const { statusCode } = useParams()

  if ( !statusCode || statusCode === `0` ) {
    return <FailurePage />
  }

  return <PayPalAuthorized />
}