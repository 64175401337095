import { plusIco } from 'assets/images'
import DOSGrouping from 'components/dosGrouping'
import { DOSItemGrouping } from 'types'

function OrderSummary({ items, total } : { items: DOSItemGrouping[], total: number }) {

  function sortByOldest( unsortedItems : DOSItemGrouping[] ) : DOSItemGrouping[] {
    return unsortedItems.sort( ( groupingA, groupingB ) => {
      const groupingADate = new Date( groupingA.dos )
      const groupingBDate = new Date( groupingB.dos )

      return groupingADate > groupingBDate ? 1 : -1
    })
  }

  if ( !items?.length ) return null

  return (
    <div className="mt-4 w-full">
      <div className="bg-primary rounded-t-lg p-3 text-white">
        <p className="font-bold text-xl ml-[20px]">{`Summary of items rented and sold`}</p>
        <div className="flex flex-row gap-2">
          <img src={plusIco} alt="plus icon" />
          <p>{`Tap any product to view an explanation of benefits`}</p>
        </div>
      </div>
      <div className="w-full">
        {
          sortByOldest( items ).map( ( groupingItem, i ) => {
            return <DOSGrouping key={i} items={groupingItem.items} dos={groupingItem.dos} />
          })
        }
      </div>
      <div className="bg-primary rounded-b-lg py-4 px-3">
        <p className="text-white font-bold ml-[20px]">{`Your Balance: $${total?.toFixed( 2 )}`}</p>
      </div>
    </div>
  )
}

export default OrderSummary