import { convertMS } from "../utils"

export const Validators = {
  name: ( value : string ) => {
    return Boolean( value.match( /^[a-zA-Z-' ]+$/ ) )
  },
  street: ( value : string ) => {
    return Boolean( value.match( /^[0-9a-zA-Z- .]+$/ ) )
  },
  city: ( value : string ) => {
    return Boolean( value.match( /^[a-zA-Z- .]+$/ ) )
  },
  state: ( value : string ) => {
    const stateRE = /(A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|PA|RI|S[CD]|T[NX]|UT|V[AT]|W[AIVY])/

    return Boolean( value.match( stateRE ) )
  },
  zipCode: ( value : string ) => {
    return Boolean( value.match( /(^\d{5}$)$/ ) )
  },
  phone: ( value : string ) => {
    return Boolean( value?.match( /^\(([2-9]\d{2})\) \d{3}-\d{4}$/ ) )
  },
  password: ( value : string ) => {
    return Boolean( value.match( /^((?=.*[A-Z])(?=.*[a-z])(?=.*\d)|(?=.*[a-z])(?=.*\d)(?=.*[$%&])|(?=.*[A-Z])(?=.*\d)(?=.*[$%&])|(?=.*[A-Z])(?=.*[a-z])(?=.*[$%&])).{8,255}$/ ) )
  },
  passwordConfirmation: ( password : string, passwordConfirmation : string ) => {
    return password === passwordConfirmation
  },
  UUID: ( value : string ) => {
    return Boolean( value.match( /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i ) )
  },
  memberID: ( value : string ) => {
    return Boolean( value.match( /^[0-9a-zA-Z-]+$/ ) && value.length > 3 )
  },
  secondaryMemberID: ( value : string ) => {
    return Boolean( value.match( /^[0-9a-zA-Z-]+$/ ) && value.length > 3 )
  },
  groupNumber: ( value : string ) => {
    return Boolean( value.match( /^\d+$/ ) )
  },
  ccv: ( value : string ) => {
    return Boolean( value.match( /^[0-9]{3,4}$/ ) )
  },
  expiryMonth: ( value : string ) => {
    return Boolean( value.match( /^(0?[1-9]|1[012])$/ ) )
  },
  expiryYear: ( value : string ) => {
    const validNumbers = Boolean( value.match( /^[2-9][0-9]$/ ) )
    const notPrevYears = ( new Date().getFullYear() % 100 ) <= Number( value )

    return validNumbers && notPrevYears // Years of [>=currentYear]-99 are valid. This will not work with a year past 2099
  },
  creditCard: ( value : string ) => {
    return value.length > 0
  },
  email: ( value : string ) => {
    return Boolean( value.match( /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i ) ) && value.length <= 50
  },
  dobMonth: ( value : number ) => {
    return value > 0 && value < 13
  },
  dd: ( value : number ) => {
    return value > 0 && value < 32
  },
  dobYear: ( val : string ) => {
    const dateStr = `${val}-01-01T00:00:00`

    // can't be older than 120!
    const today = new Date()
    const dob = new Date( dateStr )
    let valid = false

    if ( typeof dob === `string` && dob === `Invalid Date` ) return false

    const ms = ( today.getTime() - dob.getTime() )
    const { day } = convertMS( ms )
    const years = ( day / 365 )


    if ( years === 0 ){
      valid = true
    }

    if ( years > 0 && years < 120 ){
      valid = true
    }

    if ( years < 0 ){
      valid = false
    }

    return valid
  },
  liveDob: ( valueName : string, value : string ) => {
    const name = valueName === `dobDay` ? `dd` : valueName
    const lengthCap = name === `dobYear` ? 4 : 2
    if ( ( name === `dobMonth` || name === `dd` || name === `dobYear` ) && value ) {
      return ( ( value.length < lengthCap || ( value.length >= lengthCap && Validators?.[name]( value as never ) ) ) && ( /^[0-9]+$/ ).test( value ) )
    }

    return true
  },
  accountNumber: ( _input : string ) => {
    return _input.length > 4 && _input.length < 18
  },
  confirmAccountNumber: ( confirmAccountNumber : string, accountNumber : string ) => {
    return accountNumber === confirmAccountNumber
  },
  routingNumber: ( _input : string ) => {
    return _input.length === 9
  },
  accountType: ( _input : string ) => {
    return _input.length > 0 && _input === `checking` || _input === `savings`
  },
  nameOnAccount: ( _input : string ) => {
    return Boolean( _input.match( /^[a-zA-Z-' ]+$/ ) ) && _input.length > 0 && _input.length < 23
  },
  signature: ( value : string ) => {
    return Boolean( value.match( /^[a-zA-Z `'-]{5,50}$/ ) )
  },
  get firstName(){ return this.name },
  get lastName(){ return this.name },
  get username(){ return this.email },
  get dobDay(){ return this.dd },
  get phoneNumber(){ return this.phone }
} as Record<string, any>

export const Formatters = {
  phone:( value : any ) => {
    if ( !value ) return value
    const _val = value.replace( /[- )(]/g, `` ).replace( /[^0-9]/g, `` )

    if ( _val.length === 1 && _val === `1` ){
      return ``
    }

    const a = _val.length > 3 ? `(${_val.substring( 0, 3 )})` : _val
    const b = _val.substring( 3, 6 ) ? ` ${_val.substring( 3, 6 )}` : ``
    const c = _val.substring( 6 ) ? `-${_val.substring( 6, 10 )}` : ``

    // pattern `(888) 555-1212`
    return `${a}${b}${c}`
  },
  zipCode: ( value : string ) => {
    return value.replace( /[^\d+]/g, `` ).slice( 0, 5 )
  },
  creditCard: ( value : string ) => {
    const reg = /[^\d]+/gi
    if ( value ) {
      const _strval = value.replace( reg, `` )
      const _val = _strval.replace( /-/g, `` )

      const _valLength = _val.length

      const isAmex = _val.startsWith( `37` ) || _val.startsWith( `34` )

      if ( isAmex ){
        const a = _val.substring( 0, 4 )
        const b = _val.substring( 4, 10 )
        const c = _val.substring( 10, 15 )

        if ( _valLength > 10 ){
          return `${a}-${b}-${c}`
        }

        if ( _valLength > 4 ){
          return `${a}-${b}`
        }

        if ( _valLength > 0 ){
          return a
        }

      }

      if ( !isAmex ){

        const a = _val.substring( 0, 4 )
        const b = _val.substring( 4, 8 )
        const c = _val.substring( 8, 12 )
        const d = _val.substring( 12, 16 )

        if ( _valLength > 12 ){
          return `${a}-${b}-${c}-${d}`
        }

        if ( _valLength > 8 ){
          return `${a}-${b}-${c}`
        }

        if ( _valLength > 4 ){
          return `${a}-${b}`
        }

        if ( _valLength > 0 ){
          return a
        }

      }
    }

    return ``
  },
  dateYY: ( value : string ) => {
    return value.slice( -2 )
  },
  dateYYYY: ( value : string ) => {
    return value.substring( 0, 4 )
  },
  dateXX: ( value : string ) => {
    if ( Number( value ) > 0 && Number( value ) < 10 ) {
      return Formatters.prefaceDigitWithZero( value )
    }

    return value.slice( -2 )
  },
  state: ( value : string ) => {
    return value.slice( -2 )
  },
  prefaceDigitWithZero: ( value : string ) => {
    if ( value.charAt( 0 ) === `0` ) return value

    return `0${value}`
  }
}

/**
 * Get Expiry Years - returns an array of objects of the next twenty years in format YY, and YYYY
 * @returns expYears - List of objects mapped with strings of the next twenty years, in YYYY, and YY format
 */
export const getExpiryYearList = () => {
  const startingYear = ( new Date() ).getFullYear()

  return Array.from( Array( 21 ) ).map( ( _, index ) => {
    return {
      YYYY: ( startingYear + index ).toString(),
      YY: ( startingYear + index ).toString().slice( -2 )
    }
  })
}
