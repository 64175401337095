import { useState } from 'react'
import { SubscriptionToUpdateInterface } from '../types'
import EditCardMethod from 'components/payments/Edit/EditCardMethod'
import { CardPaymentMethod, BankPaymentMethod } from 'types/paymentMethods'
import { makeEmptyBankMethod, makeEmptyCardMethod } from 'components/payments/View/AddNew'
import { EditBankMethod } from 'components/payments/Edit'
import SubscriptionDisplay from './subscriptionDetailViews/SubscriptionDisplay'
import Success from './subscriptionDetailViews/Success'
import useUpdateSubscription from '../api/useUpdateSubscription'

export const SubscriptionDetail = ({ subscription } : {subscription: SubscriptionToUpdateInterface}) => {

  const {
    name,
    subscriptionId,
    associatedPaymentProfile
  } = subscription

  const [ viewType, setViewType ] = useState( `display` )

  const [ updatedPayMethod, setUpdatedPayMethod ] = useState<CardPaymentMethod>( makeEmptyCardMethod() )
  const [ updatedBankMethod, setUpdatedBankMethod ] = useState<BankPaymentMethod>( makeEmptyBankMethod() )

  const { updateSubscription, loading, error, successMessage } = useUpdateSubscription()

  async function handleUpdate( paymentType : `card` | `ach` ) {
    const message = await updateSubscription( paymentType === `card` ? updatedPayMethod : updatedBankMethod, subscriptionId )
    if ( message === `success` ) setViewType( `success` )
  }

  return (
    <div className="mb-8 bg-gray3 rounded-b-lg">

      <h4 className={`bg-primary sm:rounded-none md:rounded-t-lg lg:rounded-t-lg w-full text-white px-4 py-2 text-xl`}>
        {`Payment plan for ${name}`}
      </h4>

      {viewType === `success` && <Success message={successMessage} />}

      {viewType === `display` && (
        <SubscriptionDisplay
          subscription={subscription}
          onUpdateButtonClick={() => setViewType( `edit` )}
          shouldBeLoading={loading}
          error={error}
        />
      )}

      {viewType === `edit` && associatedPaymentProfile?.payment?.creditCard?.cardNumber &&
        <EditCardMethod
          payMethod={updatedPayMethod}
          setPayMethod={setUpdatedPayMethod}
          onFormSubmit={() => handleUpdate( `card` )}
          submitButtonText="Update Card"
          displayLoading={loading}
          onCancel={() => { return setViewType( `display` ) }}
          displayError={error}
          allowSaveForFuture={false}
        />
      }
      {viewType === `edit` && associatedPaymentProfile?.payment?.bankAccount?.accountNumber &&
        <EditBankMethod
          payMethod={updatedBankMethod}
          setPayMethod={setUpdatedBankMethod}
          onFormSubmit={() => handleUpdate( `ach` )}
          submitButtonText="Update Bank Account"
          displayLoading={loading}
          onCancel={() => { return setViewType( `display` ) }}
          displayError={error}
          allowSaveForFuture={false}
        />
      }
    </div>
  )
}

export default SubscriptionDetail