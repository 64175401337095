import { DigitalStatementSummary } from "modules/digitalStatementSummary"
import { ThankYouPage } from "modules/thankYouPage"
import { UpdatePaymentPlans } from "modules/updatePaymentPlans"
import { PaymentMethodsStandalone } from "modules/paymentMethodsStandalone"
import PayPalRedirectLanding from "modules/merchantPaymentMethods/pages/paypal"
import { MaintenancePage } from "components/maintenance"

export const MAINTENANCE_PAGE_PATH = `/maintenance`

const routes = [
  {
    path: `/`,
    element: <DigitalStatementSummary />
  },
  {
    path: `/thank-you`,
    element: <ThankYouPage />
  },
  {
    path: `/update-expired-subscription`,
    element: <UpdatePaymentPlans />
  },
  {
    path: `/payment-methods`,
    element: <PaymentMethodsStandalone headingText="Add or Edit Payment Methods" />
  },
  {
    path: `/paypal/:statusCode`,
    element: <PayPalRedirectLanding />
  },
  {
    path: MAINTENANCE_PAGE_PATH,
    element: <MaintenancePage />
  }
]

export default routes