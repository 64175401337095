import React from "react"
import usePayMethodsStore from "../../store/paymentStore"
import {serializePaymentMethod} from "../../authnet/utils"
import {charge, deletePaymentMethod} from "../../authnet/api"
import {reportToSentry} from "../../utils/Sentry/reportToSentry"
import {usePaymentStore} from "../../store"
import {useNavigate} from "react-router-dom"
import {PayPalButton } from "modules/merchantPaymentMethods/components/buttons"
import LoadingSpinner from "../loadingSpinner"
import OrPayWithSeparator from "modules/merchantPaymentMethods/components/OrPayWith"
import { useCustomAmountStore } from "components/payCustomAmount/store"
import PayCustomAmountInput from "components/payCustomAmount/input"

interface SubmitPaymentButtonProps {
  blockId: string
  onClick?: () => void
  hideCustomInput?: boolean
  containerClassName?: string
}

export default function SubmitPaymentButton({blockId, onClick, hideCustomInput = false, containerClassName = `my-4 text-center`} : SubmitPaymentButtonProps ) {
  const navigate = useNavigate()

  const { setTransactionId, selectedPaymentProfiles } = usePaymentStore() // @Note: Right now this will always be `singlePayment`, but this logic will be needed in a future iteration
  const [ payMethod ] = usePayMethodsStore( ( state ) => { return [ state.getPayMethod( selectedPaymentProfiles[blockId] ) ] })
  const { payMethods, removePayMethod } = usePayMethodsStore()
  const payMethodBeingEdited = Boolean( payMethods?.find( ( payMethod ) => { return payMethod.isEditing === true }) )
  const [ errorMessage, setErrorMessage ] = React.useState( `` )
  const [ submitLoading, setSubmitLoading ] = React.useState( false ) // This is for submit loading
  const [ fullComponentLoading, setFullComponentLoading ] = React.useState( false ) // This will hide the entire component and show a loading spinner

  const { isNotValidUserDefinedTotal } = useCustomAmountStore( ( state ) => ({
    isNotValidUserDefinedTotal: state.getIsNotValidUserDefinedTotal()
  }) )
  const userDefinedTotal = useCustomAmountStore( ( state ) => state.getUserDefinedTotal() )

  async function handlePayment(): Promise<void> {
    setSubmitLoading( true )
    setErrorMessage( `` )
    if ( !userDefinedTotal ) return

    const paymentDetails = {
      amount: userDefinedTotal.replace( `$`, `` ),
      ...serializePaymentMethod( payMethod )
    }

    const chargeData = await charge( paymentDetails, false ).catch( error => {
      reportToSentry( new Error( `There was an error charging a payment method` ), {
        cause: error
      })
    })

    const chargeResponse = chargeData?.chargeResponseMessage || ``

    if ( chargeResponse === `Successful.` ) {
      setTransactionId( chargeData?.paymentData?.transactionResponse?.transId )
      // Delete the payment methods that should be deleted, indicated by the user
      const deletePaymentMethodsPromises : Array<Promise<any>> = payMethods.map( ( pM ) => {
        if ( pM.shouldDeleteOnPayment && pM.paymentProfileId ) {
          removePayMethod( pM.paymentMethodId ) // Remove the payment method from the store, in case the user hits back
          return deletePaymentMethod( pM.paymentProfileId ) // Delete the payment method from the authnet api
        }
        return new Promise( ( resolve ) => resolve( true ) ) // Return a resolved, means it was never saved, or the user wants to save it
      })
      await Promise.all( deletePaymentMethodsPromises )

      return navigate( `/thank-you` )
    }

    reportToSentry( new Error( `The Charge Response did not come back successful` ), {
      cause: chargeResponse
    })
    setSubmitLoading( false )
    const authnetError = chargeData?.paymentData?.transactionResponse?.errors?.[0]?.errorText ?? ``
    setErrorMessage( `${chargeResponse}\n${authnetError}` )
  }

  if ( fullComponentLoading ) return <div><LoadingSpinner /></div>

  return (
    <div className={containerClassName}>

      <button
        type="button"
        disabled={submitLoading || !payMethod || payMethodBeingEdited || isNotValidUserDefinedTotal}
        onClick={onClick ?? handlePayment}
        className="btn btn-primary w-80 disabled:cursor-not-allowed"
      >
        {submitLoading ? `Submitting Payment...` : `Pay ${ userDefinedTotal }`}
      </button>
      {errorMessage && <p className="text-[red] text-center mt-2 mx-auto w-60">{errorMessage}</p>}
      { !hideCustomInput && <PayCustomAmountInput /> }
      <OrPayWithSeparator />
      <PayPalButton onClick={() => setFullComponentLoading( true )} onRequestComplete={( payPalError: string ) => { setFullComponentLoading( false ); setErrorMessage( payPalError ) }} />
    </div>
  )
}