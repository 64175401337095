import { useEffect } from 'react'
import { checkMaintenanceFlag } from 'utils/functions'
import { MAINTENANCE_PAGE_PATH } from 'routes'
import { reportToSentry } from '../../utils/reportToSentry'
import { useNavigate } from 'react-router-dom'
import { AWSResponse } from 'modules/updatePaymentPlans/api'

function MaintenanceListener() : null {

  const navigate = useNavigate()

  useEffect( () => {
    checkMaintenanceFlag()
      .then( ( data: AWSResponse<{ status: boolean}> ) => {
        if ( data?.meta?.status === `OK` && data?.data?.status ) {
          return navigate( `${MAINTENANCE_PAGE_PATH}${window.location.search}` )
        }
      })
      .catch( () => {
        // no need to prevent these folks from accessing the site, they likely have that .io domain blocked
        reportToSentry( new Error( `Issue fetching maintenance mode status` ) )
      })
  }, [] )

  return null

}

export default MaintenanceListener