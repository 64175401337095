import { SubscriptionToUpdateInterface } from '../../types'

interface SubscriptionDisplayProps {
  subscription: SubscriptionToUpdateInterface;
  onUpdateButtonClick: () => void;
  shouldBeLoading: boolean;
  error: string | null;
}

export default function SubscriptionDisplay({
  subscription,
  onUpdateButtonClick,
  shouldBeLoading,
  error
} : SubscriptionDisplayProps ) {

  const {
    paymentStartDate,
    expiredCardLastFour,
    expiredCardExpirationDate,
    invoiceNumber,
    amount,
    paymentIntervalLength,
    paymentIntervalUnit,
    associatedPaymentProfile,
    status,
    paymentsCompleted,
    lastDeclinedDate,
    paymentsRemaining,
    dollarRemaining
  } = subscription

  const paymentProfileType = associatedPaymentProfile?.payment?.creditCard?.cardNumber ? `card` : `ach`
  const formattedIntervalLengthText = paymentIntervalLength > 1 ? paymentIntervalLength : ``
  const formattedUnitText = paymentIntervalLength > 1 ? paymentIntervalUnit : paymentIntervalUnit.slice( 0, -1 )

  const formatCurrency = ( amount: number ) => {
    return amount.toLocaleString( `en-US`, {
      style: `currency`,
      currency: `USD`
    })
  }

  const SubscriptionDetailLineItem = ({label, value} : {label: string, value?: string | number | null}) => {
    return (
      <p className="my-2 text-responsive-sm"><b>{`${label} `}</b>{value ?? ``}</p>
    )
  }

  return (
    <div className="w-full">
      <div className="px-4 py-2">
        <SubscriptionDetailLineItem
          label={`Plan ${invoiceNumber?.length ? `(#${invoiceNumber})` : ``} start date: `}
          value={new Date( paymentStartDate ).toDateString()}
        />
        <SubscriptionDetailLineItem
          label={`Amount owed per ${formattedIntervalLengthText} ${formattedUnitText}: `}
          value={formatCurrency( amount ?? 0 )}
        />
        <SubscriptionDetailLineItem
          label={`Payments Completed: `}
          value={paymentsCompleted ?? 0}
        />
        <SubscriptionDetailLineItem
          label="Date of Last Failed Payment:"
          value={lastDeclinedDate ? new Date( lastDeclinedDate as string ).toDateString() : `Date not available`}
        />
        <SubscriptionDetailLineItem
          label="Total Payments Remaining:"
          value={paymentsRemaining ?? 0}
        />
        <SubscriptionDetailLineItem
          label={`Total Balance Remaining: `}
          value={formatCurrency( dollarRemaining ?? 0 )}
        />
      </div>
      <div>
        <div className="border-primary border px-4 py-2">
          {status === `suspended` || status == `expired` && <p className="font-bold text-lg text-primary">{`To keep this plan active please update the expired payment:`}</p>}
          {/* @NOTE: This active text will only display on dev sites unless, you change the filter rule in useFetchPaymentPlans.tsx */}
          {status === `active` && <p className="font-bold text-lg text-primary">{`This payment plan is currently active. No update needed`}</p>}
          {
            paymentProfileType === `card` ?
              <p>{`Last four digits: ${expiredCardLastFour.slice( -4 )} Expiration date: ${expiredCardExpirationDate}`}</p>
              :
              <p>{`Last four digits of ${associatedPaymentProfile?.payment?.bankAccount?.accountType ?? ``} account: ${associatedPaymentProfile?.payment?.bankAccount?.accountNumber?.slice( -4 )}`}</p>
          }

        </div>

        <div className="w-80 mx-auto py-2">
          <button
            onClick={onUpdateButtonClick}
            disabled={shouldBeLoading}
            className="btn btn-primary w-full"
          >
            {`Update Now`}
          </button>
        </div>
        {error && <p className="text-[red] px-4 py-2">{error}</p>}
      </div>
    </div>
  )
}