
// Regex error must be added for input to properly validate. Re-use first! Don't change any of the key/value pairs above unless instructed by form SOP coordinator.
// @NOTE whatever you do don't change these keys!!
export const regexErrors = {
  memberID: `Member ID cannot contain special characters or spaces. Please use valid letters, numbers and dashes. Must be more than 3 characters`,
  secondaryMemberID: `Member ID cannot contain special characters or spaces. Please use valid letters, numbers and dashes. Must be more than 3 characters`,
  email: `Enter a valid email`,
  dd: `Please enter a day between 01 - 31`,
  card_number: `Enter a valid card number`,
  expmm: `Please enter a month between 01 - 12`,
  expyyyy: `Enter valid expiration year`,
  ccv: `Enter a valid ccv`,
  first_name: `Enter a valid first name. Alphabet characters, spaces, dashes and apostrophes are allowed. Must be more than 1 letter`,
  last_name: `Enter a valid last name. Alphabet characters, spaces, dashes and apostrophes are allowed. Must be more than 1 letter`,
  name: `Enter a valid name. Alphabet characters, spaces, dashes and apostrophes are allowed. Must be more than 1 letter`,
  phone: `Enter a valid phone number`,
  dob_yyyy: `Please enter a valid 4 digit year`,
  zip: `Zip code must contain 5 numbers`,
  address: `Special characters are not accepted besides dashes and periods. Please use valid letters (a-z or A-Z) numbers (0-9) or spaces. Must be more than 1 letter`,
  city: `Special characters are not accepted besides dashes and periods. Please use valid letters (a-z or A-Z) or spaces. Must be more than 1 letter`,
  memberId: `Must be longer than 3 characters. No special characters allowed except for dashes (-)`,
  dueDateYYYY: `Please enter a valid 4 digit year within 1 year of the current year`,
  weight: `Please enter a weight between 1 and 999 lbs.`,
  date_of_birth: `Please enter a valid date by month (01 - 12), day (01 - 31), and year within the past 120 years`,
  due_date: `Please enter a valid date by month (01 - 12), day (01 - 31). Must be prior to 9 months from current date and no later than 1 year in the past.`,
  state: `Please enter a state`,
  insuranceType: `Please select insurance type.`,
  secondaryInsuranceType: `Please select insurance type.`,
  howDidYouHearAboutUs: `Please select how you heard about us.`,
  password: `Your password must be at least 8 characters, contain lower case, upper case, and at least one number.`,
  passwordConfirmation: `Your passwords must match.`,
  terms: `You must accept the terms & conditions to continue`,
  accountNumber: `Please enter a valid bank account number`,
  routingNumber: `Please enter a valid bank routing number`,
  confirmAccountNumber: `Account numbers must match`
} as Record<string, string>

// This is a list of fields that are dependent on other fields to validate
export const AssociatedFields = {
  'confirmAccountNumber': `accountNumber`
} as Record<string, string>

export const months = [
  {
    value: `01`,
    text: `January`
  },
  {
    value: `02`,
    text: `Febuary`
  },
  {
    value: `03`,
    text: `March`
  },
  {
    value: `04`,
    text: `April`
  },
  {
    value: `05`,
    text: `May`
  },
  {
    value: `06`,
    text: `June`
  },
  {
    value: `07`,
    text: `July`
  },
  {
    value: `08`,
    text: `August`
  },
  {
    value: `09`,
    text: `September`
  },
  {
    value: `10`,
    text: `October`
  },
  {
    value: `11`,
    text: `November`
  },
  {
    value: `12`,
    text: `December`
  }
] as Array<{ value: string, text: string }>

export const usaStates = [ // USA
  {
    value: `AL`,
    text: `Alabama`
  },
  {
    value: `AK`,
    text: `Alaska`
  },
  {
    value: `AZ`,
    text: `Arizona`
  },
  {
    value: `AR`,
    text: `Arkansas`
  },
  {
    value: `CA`,
    text: `California`
  },
  {
    value: `CO`,
    text: `Colorado`
  },
  {
    value: `CT`,
    text: `Connecticut`
  },
  {
    value: `DE`,
    text: `Delaware`
  },
  {
    value: `DC`,
    text: `District of Columbia`
  },
  {
    value: `FL`,
    text: `Florida`
  },
  {
    value: `GA`,
    text: `Georgia`
  },
  {
    value: `HI`,
    text: `Hawaii`
  },
  {
    value: `ID`,
    text: `Idaho`
  },
  {
    value: `IL`,
    text: `Illinois`
  },
  {
    value: `IN`,
    text: `Indiana`
  },
  {
    value: `IA`,
    text: `Iowa`
  },
  {
    value: `KS`,
    text: `Kansas`
  },
  {
    value: `KY`,
    text: `Kentucky`
  },
  {
    value: `LA`,
    text: `Louisiana`
  },
  {
    value: `ME`,
    text: `Maine`
  },
  {
    value: `MD`,
    text: `Maryland`
  },
  {
    value: `MA`,
    text: `Massachusetts`
  },
  {
    value: `MI`,
    text: `Michigan`
  },
  {
    value: `MN`,
    text: `Minnesota`
  },
  {
    value: `MS`,
    text: `Mississippi`
  },
  {
    value: `MO`,
    text: `Missouri`
  },
  {
    value: `MT`,
    text: `Montana`
  },
  {
    value: `NE`,
    text: `Nebraska`
  },
  {
    value: `NV`,
    text: `Nevada`
  },
  {
    value: `NH`,
    text: `New Hampshire`
  },
  {
    value: `NJ`,
    text: `New Jersey`
  },
  {
    value: `NM`,
    text: `New Mexico`
  },
  {
    value: `NY`,
    text: `New York`
  },
  {
    value: `NC`,
    text: `North Carolina`
  },
  {
    value: `ND`,
    text: `North Dakota`
  },
  {
    value: `OH`,
    text: `Ohio`
  },
  {
    value: `OK`,
    text: `Oklahoma`
  },
  {
    value: `OR`,
    text: `Oregon`
  },
  {
    value: `PA`,
    text: `Pennsylvania`
  },
  {
    value: `RI`,
    text: `Rhode Island`
  },
  {
    value: `SC`,
    text: `South Carolina`
  },
  {
    value: `SD`,
    text: `South Dakota`
  },
  {
    value: `TN`,
    text: `Tennessee`
  },
  {
    value: `TX`,
    text: `Texas`
  },
  {
    value: `UT`,
    text: `Utah`
  },
  {
    value: `VT`,
    text: `Vermont`
  },
  {
    value: `VA`,
    text: `Virginia`
  },
  {
    value: `WA`,
    text: `Washington`
  },
  {
    value: `WV`,
    text: `West Virginia`
  },
  {
    value: `WI`,
    text: `Wisconsin`
  },
  {
    value: `WY`,
    text: `Wyoming`
  }
] as Array<{ value: string, text: string }>

// These are M2 address regions
export const usaShippingRegions = [
  {
    "__typename": `Region`,
    "id": 1,
    "code": `AL`,
    "name": `Alabama`
  },
  {
    "__typename": `Region`,
    "id": 2,
    "code": `AK`,
    "name": `Alaska`
  },
  {
    "__typename": `Region`,
    "id": 4,
    "code": `AZ`,
    "name": `Arizona`
  },
  {
    "__typename": `Region`,
    "id": 5,
    "code": `AR`,
    "name": `Arkansas`
  },
  {
    "__typename": `Region`,
    "id": 12,
    "code": `CA`,
    "name": `California`
  },
  {
    "__typename": `Region`,
    "id": 13,
    "code": `CO`,
    "name": `Colorado`
  },
  {
    "__typename": `Region`,
    "id": 14,
    "code": `CT`,
    "name": `Connecticut`
  },
  {
    "__typename": `Region`,
    "id": 15,
    "code": `DE`,
    "name": `Delaware`
  },
  {
    "__typename": `Region`,
    "id": 16,
    "code": `DC`,
    "name": `District of Columbia`
  },
  {
    "__typename": `Region`,
    "id": 18,
    "code": `FL`,
    "name": `Florida`
  },
  {
    "__typename": `Region`,
    "id": 19,
    "code": `GA`,
    "name": `Georgia`
  },
  {
    "__typename": `Region`,
    "id": 22,
    "code": `ID`,
    "name": `Idaho`
  },
  {
    "__typename": `Region`,
    "id": 23,
    "code": `IL`,
    "name": `Illinois`
  },
  {
    "__typename": `Region`,
    "id": 24,
    "code": `IN`,
    "name": `Indiana`
  },
  {
    "__typename": `Region`,
    "id": 25,
    "code": `IA`,
    "name": `Iowa`
  },
  {
    "__typename": `Region`,
    "id": 26,
    "code": `KS`,
    "name": `Kansas`
  },
  {
    "__typename": `Region`,
    "id": 27,
    "code": `KY`,
    "name": `Kentucky`
  },
  {
    "__typename": `Region`,
    "id": 28,
    "code": `LA`,
    "name": `Louisiana`
  },
  {
    "__typename": `Region`,
    "id": 29,
    "code": `ME`,
    "name": `Maine`
  },
  {
    "__typename": `Region`,
    "id": 31,
    "code": `MD`,
    "name": `Maryland`
  },
  {
    "__typename": `Region`,
    "id": 32,
    "code": `MA`,
    "name": `Massachusetts`
  },
  {
    "__typename": `Region`,
    "id": 33,
    "code": `MI`,
    "name": `Michigan`
  },
  {
    "__typename": `Region`,
    "id": 34,
    "code": `MN`,
    "name": `Minnesota`
  },
  {
    "__typename": `Region`,
    "id": 35,
    "code": `MS`,
    "name": `Mississippi`
  },
  {
    "__typename": `Region`,
    "id": 36,
    "code": `MO`,
    "name": `Missouri`
  },
  {
    "__typename": `Region`,
    "id": 37,
    "code": `MT`,
    "name": `Montana`
  },
  {
    "__typename": `Region`,
    "id": 38,
    "code": `NE`,
    "name": `Nebraska`
  },
  {
    "__typename": `Region`,
    "id": 39,
    "code": `NV`,
    "name": `Nevada`
  },
  {
    "__typename": `Region`,
    "id": 40,
    "code": `NH`,
    "name": `New Hampshire`
  },
  {
    "__typename": `Region`,
    "id": 41,
    "code": `NJ`,
    "name": `New Jersey`
  },
  {
    "__typename": `Region`,
    "id": 42,
    "code": `NM`,
    "name": `New Mexico`
  },
  {
    "__typename": `Region`,
    "id": 43,
    "code": `NY`,
    "name": `New York`
  },
  {
    "__typename": `Region`,
    "id": 44,
    "code": `NC`,
    "name": `North Carolina`
  },
  {
    "__typename": `Region`,
    "id": 45,
    "code": `ND`,
    "name": `North Dakota`
  },
  {
    "__typename": `Region`,
    "id": 47,
    "code": `OH`,
    "name": `Ohio`
  },
  {
    "__typename": `Region`,
    "id": 48,
    "code": `OK`,
    "name": `Oklahoma`
  },
  {
    "__typename": `Region`,
    "id": 49,
    "code": `OR`,
    "name": `Oregon`
  },
  {
    "__typename": `Region`,
    "id": 51,
    "code": `PA`,
    "name": `Pennsylvania`
  },
  {
    "__typename": `Region`,
    "id": 53,
    "code": `RI`,
    "name": `Rhode Island`
  },
  {
    "__typename": `Region`,
    "id": 54,
    "code": `SC`,
    "name": `South Carolina`
  },
  {
    "__typename": `Region`,
    "id": 55,
    "code": `SD`,
    "name": `South Dakota`
  },
  {
    "__typename": `Region`,
    "id": 56,
    "code": `TN`,
    "name": `Tennessee`
  },
  {
    "__typename": `Region`,
    "id": 57,
    "code": `TX`,
    "name": `Texas`
  },
  {
    "__typename": `Region`,
    "id": 58,
    "code": `UT`,
    "name": `Utah`
  },
  {
    "__typename": `Region`,
    "id": 59,
    "code": `VT`,
    "name": `Vermont`
  },
  {
    "__typename": `Region`,
    "id": 60,
    "code": `VI`,
    "name": `Virgin Islands`
  },
  {
    "__typename": `Region`,
    "id": 61,
    "code": `VA`,
    "name": `Virginia`
  },
  {
    "__typename": `Region`,
    "id": 62,
    "code": `WA`,
    "name": `Washington`
  },
  {
    "__typename": `Region`,
    "id": 63,
    "code": `WV`,
    "name": `West Virginia`
  },
  {
    "__typename": `Region`,
    "id": 64,
    "code": `WI`,
    "name": `Wisconsin`
  },
  {
    "__typename": `Region`,
    "id": 65,
    "code": `WY`,
    "name": `Wyoming`
  }
] as Array<{ id: number, code: string, name: string, __typename: string }>