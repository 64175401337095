import { infoCircle, xIco } from "assets/images"
import { Tooltip } from "react-tooltip"
import { useMemo, useRef, useState } from "react"

interface InfoTooltipProps {
  title: string;
  description: string | JSX.Element;
  icoSize?: `sm` | `md` | `lg`;
}

export default function InfoTooltip({title, description, icoSize} : InfoTooltipProps ) {
  const tooltipRef = useRef<HTMLDivElement>( null )
  const [ isOpen, setIsOpen ] = useState( false )

  const icoClassName = useMemo( () => {
    switch ( icoSize ) {
    case `lg`:
      return `sm:w-5 sm:h-5 md:w-[22px] md:h-[22px] lg:w-[24px] lg:h-[24px]`
    case `md`:
      return `sm:w-[18px] sm:h-[18px] md:w-[20px] md:h-[20px] lg:w-[22px] lg:h-[22px]`
    case `sm`:
    default:
      return `sm:w-[16px] sm:h-[16px] md:w-[18px] md:h-[18px] lg:w-[20px] lg:h-[20px]`
    }
  }, [ icoSize ] )


  return (
    <div
      ref={tooltipRef}
    >
      <Tooltip
        id={title} place="top" isOpen={isOpen}
        className="sm:max-w-xs md:max-w-sm lg:max-w-md shadow-md z-10" // Note: see index.css for additional styling since react-tooltip doesn't allow for all custom classes
        openOnClick
        variant="light"
        opacity={100}
        clickable
        closeEvents={{
          click: true,
          dblclick: true,
          blur: true
        }}
        positionStrategy="fixed"
        setIsOpen={setIsOpen}
        render={( () => (
          <>
            <div className="flex flex-row items-center gap-2 mb-2">
              <img
                className={icoClassName} src={infoCircle} alt={`info icon`}
              />
              <p className="font-bold text-responsive-xl text-primary">{title}</p>
              <img
                className="ml-auto cursor-pointer" src={xIco} alt="close icon"
                height={16} width={16}
                onClick={() => setIsOpen( false )}
              />
            </div>
            {description}
          </>
        )
        )}
      />
      <img
        className={icoClassName} src={infoCircle} alt={`info icon`}
        data-tip
        data-tooltip-id={title}
      />
    </div>
  )
}