import OrderItem from 'components/orderItem'
import { OrderItemType } from 'types'
import { formatDate } from './utils'
import InfoTooltip from 'components/infoTooltip'

function DOSGrouping({ items, dos } : { items : OrderItemType[], dos: string }) {

  const formattedDate = formatDate( dos )

  return (
    <div className="pb-4 w-full bg-gray3">
      <div className="flex flex-row items-center gap-1 bg-[#D4D4D4] text-black pl-8 py-1">
        <InfoTooltip title="Date of Service" description={`Specific time at which a patient received supplies, products or services.`} />
        <p className="text-responsive-sm">
          {`Date of Service: ${formattedDate}`}
        </p>
      </div>

      {
        items?.length > 0 &&
          items.map( ( orderItem ) => {
            return <OrderItem key={orderItem.hcpc_code} orderItem={orderItem} />
          })
      }
    </div>
  )
}

export default DOSGrouping