import { useCustomAmountStore } from "components/payCustomAmount/store"
export default function OrPayWithSeparator({amountText = ``} : {amountText?: string}) {
  const { shouldUseCustomAmount, userDefinedTotal } = useCustomAmountStore( ( state ) => ({
    shouldUseCustomAmount: state.shouldUseCustomAmount,
    userDefinedTotal: state.getUserDefinedTotal()
  }) )
  return (
    <div className="flex flex-row gap-2 w-80 mx-auto justify-evenly items-center my-3">
      <div className="bg-aeroflow-health w-1/3 h-0.5"></div>
      <p className="text-center">{`Pay ${amountText || shouldUseCustomAmount ? userDefinedTotal : ``} with`}</p>
      <div className="bg-aeroflow-health w-1/3 h-0.5"></div>
    </div>
  )
}