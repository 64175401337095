import { useEffect } from 'react'
import { default as create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

export interface customAmountStore {
  customAmount: string;
  total: string;
  shouldUseCustomAmount: boolean;
  setCustomAmount: ( _newCustomAmount: string ) => void;
  setShouldUseCustomAmount: ( _shouldUseCustomAmount: boolean ) => void;
  customAmountError: string;
  setCustomAmountError: ( _customAmountError: string ) => void;
  initializeAmount: ( _total: string ) => void;
  getUserDefinedTotal: () => string; // Will return the amount the user wants to pay prefixed with a dollar sign
  getIsNotValidUserDefinedTotal: () => boolean; // Will return false if the user defined total is valid
}

export const useCustomAmountStore = create<customAmountStore>()( persist( ( set, get ) => ({
  total: ``, // The numerical total amount the user owes
  customAmount: ``, // The dollar sign amount the user defines (either the $total or a $customAmount)
  shouldUseCustomAmount: false,
  setCustomAmount: ( newCustomAmount: string ) => ( set( () => ({
    customAmount: newCustomAmount
  }) ) ),
  setShouldUseCustomAmount: ( shouldUseCustomAmount: boolean ) => ( set( () => ({
    shouldUseCustomAmount
  }) ) ),
  customAmountError: ``,
  setCustomAmountError: ( customAmountError: string ) => ( set( () => ({
    customAmountError
  }) ) ),
  initializeAmount: ( total: string ) => ( set( () => ({
    customAmount: `$${total}`,
    total
  }) ) ),
  getUserDefinedTotal: () => get().shouldUseCustomAmount ? get().customAmount : `$${get().total}`,
  getIsNotValidUserDefinedTotal: () => { return ( get().shouldUseCustomAmount && Boolean( get().customAmountError.length ) ) }
}), {
  name: `customAmount`,
  storage: createJSONStorage( () => sessionStorage )
}) )

export const useInitializeCustomAmount = ( customAmount: string ) => {
  const { initializeAmount } = useCustomAmountStore()
  useEffect( () => initializeAmount( customAmount ), [] )
  return null
}