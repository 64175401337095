export const OrderItemDescriptions = {
  hcpc_code: `The Healthcare Common Procedure Coding System produced by the Centers for Medicare and Medicaid Services (CMS) that represent supplies, products and services. The codes are used to facilitate the processing of health insurance claims by insurers.`,
  hcpc_description: `Written representation of the HCPC code and the supplies, products and services received by a patient.`,
  insurance_allowed: `The maximum amount an insurance plan will pay for a covered health care service or supply.`,
  insurance_paid: `The amount the insurer paid Aeroflow Healthcare for services and supplies based on the allowed amount and the patient's benefits during a coverage period.`,
  amount_owed: ``,
  date_of_service: `Specific time at which a patient received supplies, products or services.`
}

export function PatientResponsibilityDescription() {
  return (
    <div>
      <p>{`The total amount of Deductible and Coinsurance owed by a patient for health care services and supplies.`}</p>
      <p className="font-bold text-base text-primary">{`Coinsurance`}</p>
      <p>{`Your share of the costs of a covered health care service or supply, calculated as a percent (for example, 20%) of the allowed amount for the service or supply. You pay the coinsurance plus any deductibles you owe.`}</p>
      <p className="font-bold text-base text-primary">{`Deductible`}</p>
      <p>{`The amount you owe during a coverage period (typically one year) for covered health care services and supplies before your plan begins to pay. For example, if your deductible is $1,000, your plan won’t pay anything until you’ve met your $1,000 deductible for covered health care services subject to deductible.`}</p>
    </div>
  )
}