import Logo from "components/logo"
import { useSearchParams } from "react-router-dom"

export default function FailurePage() {
  const [ searchParams ] = useSearchParams()
  return (
    <>
      <div className="flex justify-center items-center bg-white rounded-lg w-screen max-w-7xl px-4">
        <Logo />
      </div>
      <div className="flex flex-col items-center justify-center gap-1">
        <p>{`We are unable to pay with PayPal at this time.`}</p>
        { searchParams?.get( `message` ) && <p>{`PayPal returned with `}<span className="text-error">{decodeURIComponent( searchParams?.get( `message` ) ?? `` )}</span></p> }
        <p>{`Please try a different payment method or try again later.`}</p>
        <button className="btn btn-primary" type="button" onClick={() => window.location.href = `/?sgh=${searchParams.get( `sgh` )}#paymentMethods`}>{`Return to Payment Methods`}</button>
      </div>
    </> )
}