import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { checkMaintenanceFlag } from 'utils'
import { reportToSentry } from 'utils/reportToSentry'
import LoadingSpinner from 'components/loadingSpinner'
import Logo from 'components/logo'

function MaintenancePage() {

  const [ statusMessage, setStatusMessage ] = React.useState( `` )
  const [ loading, setLoading ] = React.useState( false )

  const navigate = useNavigate()

  const handleNavigate = () => {
    setLoading( true )
    setStatusMessage( `` )
    checkMaintenanceFlag()
      .then( data => {
        setLoading( false )
        if ( data?.meta?.status === `OK` && typeof data?.data?.status === `boolean` ) {
          if ( !data.data.status ) {
            // site was taken out of maintenance mode
            navigate( `/${window.location.search}` )
          }

          return setStatusMessage( `We are currently still working on the site... Thank you for your patience.` )

        }

        setStatusMessage( `We encountered an error checking the site status. Please reload the page and try again...` )
      })
      .catch( () => {
        setLoading( false )
        setStatusMessage( `We encountered an error checking the site status. Please reload the page and try again...` )
        reportToSentry( new Error( `checkMaintenanceFlag on Maintenance Page Error` ) )
      })

  }

  return (
    <div className="text-center w-11/12 mx-auto">
      <div className="mx-auto mt-5 flex items-center justify-center w-96">
        <Logo />
      </div>
      <div className="mt-8">
        <h1 className="mb-6 text-2xl md:text-4xl">{`Our website is currently taking a nap...`}</h1>
        <p className="my-2">
          {`Our server is taking a quick nap! Please try again in a few minutes.`}
        </p>
        <div className="max-w-xl mx-auto flex flex-col justify-center">
          <div className="my-2 mx-auto">
            <button
              className="btn-secondary"
              onClick={handleNavigate}
              disabled={loading}
            >
              { loading ?
                <LoadingSpinner rings={3} height="1.5rem" width="1.5rem" />
                :
                `Check Status`
              }
            </button>
          </div>
          {
            statusMessage &&
              <p className="text-error">{statusMessage}</p>
          }
        </div>
      </div>
    </div>
  )
}

export default MaintenancePage