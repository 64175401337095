import PayMethod from './PayMethod'
import { type PaymentProfileResponse } from 'types/paymentMethods'
import AddNewPayMethod from './View/AddNew'
import { useFetch } from 'hooks'
import { serializePaymentProfileResponse } from './utils'
import { useState } from 'react'
import usePayMethodsStore from "store/paymentStore"
import LoadingSpinner from "../loadingSpinner"
import {SubmitPaymentButton} from "../submitPaymentButton"
import { useCustomAmountStore, useInitializeCustomAmount } from "components/payCustomAmount/store"
import { Modal, Box } from '@mui/material'
import CustomModal from 'components/modal/customModal'

export default function PayMethodsBlock({blockId, total} : {blockId: string, total: string}) {

  const [ openConfirmation, setOpenConfirmation ] = useState<boolean>( false )

  const [ payMethods, setPayMethods ] = usePayMethodsStore( ( state ) => { return [ state.payMethods, state.setPayMethods ] })
  const { toggleSelectedPayMethod } = usePayMethodsStore()
  const [ shouldSkipFetch, setShouldSkipFetch ] = usePayMethodsStore( ( state ) => { return [ state.shouldSkipFetch, state.setShouldSkipFetch ] })
  const payMethodBeingEdited = Boolean( payMethods?.find( ( payMethod ) => { return payMethod.isEditing === true }) )
  const [ shouldAddNewBeExpanded, setShouldAddNewBeExpanded ] = useState( payMethods?.length === 0 )
  const userDefinedTotal = useCustomAmountStore( ( state ) => state.getUserDefinedTotal() )

  useInitializeCustomAmount( total )

  const { isLoading } = useFetch<PaymentProfileResponse>( `${process.env.REACT_APP_AUTHNET_PAYMENTS_V2_URL}/profile`, {
    method: `GET`
  }, {
    skipOnMount: shouldSkipFetch,
    onErrorOverride: ( _error : unknown ) => {
      // We don't want to do anything on error since this is fetching existing payment profiles
    },
    onSuccess: ( data: PaymentProfileResponse ) => {
      setShouldSkipFetch( true ) // This allows for us to not fetch the payment methods again if they have already been fetched from another block
      if ( data?.messages?.resultCode?.toLowerCase() === `ok` ) {
        const _payMethods = serializePaymentProfileResponse( data )
        setPayMethods( _payMethods )
        const lastSavedPayMethod = _payMethods?.length > 0 ? _payMethods[ _payMethods.length - 1 ]?.paymentMethodId : null
        if ( lastSavedPayMethod !== null ) {
          toggleSelectedPayMethod( blockId, lastSavedPayMethod ) // Automatically select the last saved payMethod
          setShouldAddNewBeExpanded( false ) // Have the add new payment not be expanded when a payment is already loaded
        }
        setShouldSkipFetch( true )
      }
    }
  })

  if ( isLoading ) return (
    <div className="max-w-lg mx-auto my-10 text-center">
      <p className="my-2">{`Loading payment profiles...`}</p>
      <LoadingSpinner />
    </div> )

  const nonSavedPaymentMethods = payMethods?.filter( ( payMethod ) => { return !payMethod.paymentProfileId })

  return (
    <div key={blockId}>
      <div className="flex flex-col gap-2">
        <div>
          <p className="text-lg md:text-xl font-bold text-center">{`Payment Methods`}</p>
          <p className="text-center">{Number( total ) > 0 && `Select a payment method to pay your bill.`}</p>
        </div>

        {nonSavedPaymentMethods?.length > 0 && (
          <div className="flex flex-col">
            { nonSavedPaymentMethods?.map( ( payMethod, i ) => {
              const { paymentMethodId } = payMethod
              return (
                <PayMethod
                  key={paymentMethodId ? paymentMethodId : i}
                  payMethodId={paymentMethodId}
                  blockId={blockId}
                />
              )
            })
            }

          </div>
        ) }

        {!nonSavedPaymentMethods?.length && (
          <div className="w-full">
            { payMethods?.map( ( payMethod ) => {

              const { paymentMethodId, paymentProfileId } = payMethod
              // if an item has not been saved it won't have a payment profile id
              // if the shouldAddNewBeHidden is true then we are editing a payment method
              // in that case we need to hide the ones with payment id not to confuse the user
              if ( payMethodBeingEdited && paymentProfileId ) return null

              return (
                <PayMethod
                  key={paymentMethodId}
                  payMethodId={paymentMethodId}
                  blockId={blockId}
                  paymentProfileId={paymentProfileId}
                />
              )
            })
            }
          </div>
        ) }
        <CustomModal
          open={openConfirmation}
          setOpen={setOpenConfirmation}
        >
          <Box className="flex flex-col gap-5 p-5 max-w-md w-full">
            <p className="text-lg md:text-xl font-bold text-center">{`Payment Confirmation`}</p>
            <p className="text-center max-w-xs">
              {`Ready to submit your payment of `}
              <span className="font-semibold">{ userDefinedTotal }</span>
              {`? If so, please click the button below.`}</p>
            <div className="flex flex-col items-center justify-center">
              <SubmitPaymentButton
                blockId={blockId}
                hideCustomInput
                containerClassName="text-center"
              />
              <button
                className="btn btn-secondary w-80"
                onClick={() => setOpenConfirmation( false )}
              >
                {`Review Payment`}
              </button>
            </div>
          </Box>
        </CustomModal>
        {!payMethodBeingEdited && <AddNewPayMethod expanded={shouldAddNewBeExpanded} setExpanded={setShouldAddNewBeExpanded} />}
        {payMethods.length > 0 && Number( total ) > 0 && (
          <SubmitPaymentButton
            blockId={blockId}
            onClick={() => setOpenConfirmation( true )}
          />
        )}
      </div>
    </div>
  )
}