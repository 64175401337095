import React, { useState} from 'react'
import { BankPaymentMethod, CardPaymentMethod, IPaymentMethod, PaymentProfileResponse, UpdateCardPaymentBody } from 'types/paymentMethods'
import { ViewPayMethod } from './View'
import { EditCardMethod, EditBankMethod } from './Edit'
import { PaymentMethodProps } from './props.d'
import { useFetch } from 'hooks'
import usePayMethodsStore from "../../store/paymentStore"
import {putOrPostUrlPaymentMethod} from "./utils"
import {serializeBankPaymentMethod, serializeCardPaymentMethod} from "../../authnet/utils"
import { UpdateCardMethod } from './Update'

const PaymentMethod = ({payMethodId, paymentProfileId, blockId} : PaymentMethodProps ) => {

  const [ payMethod, setPayMethod ] = usePayMethodsStore( ( state ) => { return [ state.getPayMethod( payMethodId ), state.setPayMethod ] })
  const [ updatePayMethod, setUpdatePayMethod ] = useState<CardPaymentMethod | null>( null )
  const { removePayMethod, selectedPaymentProfiles, toggleSelectedPayMethod } = usePayMethodsStore()
  const isSelected = selectedPaymentProfiles[ blockId ] === payMethodId
  const toggleSelect = () => { return toggleSelectedPayMethod( blockId, payMethodId ) }

  const {lazyFetch : savePayMethodToAuthnet, isLoading : isSaveLoading } = useFetch<PaymentProfileResponse>( putOrPostUrlPaymentMethod( payMethod ), {
    method: payMethod?.paymentProfileId?.length ? `PUT` : `POST`
  })

  const { lazyFetch : deletePayMethodFromAuthnet, isLoading : isDeleteLoading } = useFetch<PaymentProfileResponse>( `${process.env.REACT_APP_AUTHNET_PAYMENTS_V2_URL}/payment-profile/${payMethod?.paymentProfileId}`, {
    method: `DELETE`
  })

  const [ fetchError, setFetchError ] = useState<string>( `` )

  const paymentMethodType = payMethod?.paymentMethodType ?? null

  const handleSavePayMethod = async () => {

    setFetchError( `` )

    const serializedPayMethod = payMethod?.paymentMethodType === `card` ? serializeCardPaymentMethod( payMethod as CardPaymentMethod ) : serializeBankPaymentMethod( payMethod as BankPaymentMethod )
    // Note: If the user doesn't want to save payment method, we remove it after the payment has been made
    const data = await savePayMethodToAuthnet( serializedPayMethod ) // We might need to serialize this
    if ( data?.customerPaymentProfileId && data?.messages?.resultCode?.toLowerCase() === `ok` ) {
      toggleSelect() // Select new payment methods

      setPayMethod({
        ...payMethod,
        paymentProfileId: data.customerPaymentProfileId, // append the customerProfileId to the payMethod
        isEditing: false
      } as IPaymentMethod )
    } else return setFetchError( data?.messages?.message?.[0]?.text ?? `We are unable to process your payment method at this time` )
  }

  const handleUpdatePayMethod = async ( updatedPayInfo: UpdateCardPaymentBody ) => {
    setFetchError( `` )
    const data = await savePayMethodToAuthnet( updatedPayInfo )

    setUpdatePayMethod( null )

    if ( data?.messages?.resultCode?.toLowerCase() === `ok` ) {
      setPayMethod({
        ...payMethod,
        expMonth: updatedPayInfo.expirationDate?.substring( 5, 7 ),
        expYear: updatedPayInfo.expirationDate?.substring( 0, 4 ),
        cardholderFirstName: updatedPayInfo.firstName,
        cardholderLastName: updatedPayInfo.lastName,
        address: updatedPayInfo.address,
        city: updatedPayInfo.city,
        state: updatedPayInfo.state,
        zip: updatedPayInfo.zip,
        isEditing: false
      } as IPaymentMethod )
    } else return setFetchError( data?.messages?.message?.[0]?.text ?? `We are unable to update your payment method at this time` )

  }

  async function deletePaymentMethod() {

    setFetchError( `` )
    if ( !paymentProfileId ) return removePayMethod( `` )

    const response = await deletePayMethodFromAuthnet()
    if ( response?.messages?.resultCode?.toLowerCase() === `ok` ) return removePayMethod( payMethodId )
    else return setFetchError( response?.messages?.message?.[0]?.text ?? `We are unable to process your payment method at this time` )
  }

  if ( updatePayMethod ) {
    return <UpdateCardMethod
      payMethod={updatePayMethod}
      onFormSubmit={handleUpdatePayMethod}
      onCancel={() => {
        setUpdatePayMethod( null )
      }}
      displayLoading={isSaveLoading}
      displayError={fetchError}
    />
  }


  // this isn't actually editing the card it is adding it
  /* --- Edit Current Payment Method --- */
  if ( payMethod?.isEditing ) {
    if ( paymentMethodType === `card` ) {
      return (
        <EditCardMethod
          payMethod={( payMethod ) as CardPaymentMethod} setPayMethod={setPayMethod}
          onFormSubmit={handleSavePayMethod}
          onCancel={() => { return removePayMethod( payMethodId ) }}
          displayLoading={isSaveLoading}
          displayError={fetchError}
          allowSaveForFuture
        />
      )
    }

    return (
      <EditBankMethod
        payMethod={( payMethod ) as BankPaymentMethod} setPayMethod={setPayMethod}
        onFormSubmit={handleSavePayMethod}
        onCancel={() => { return removePayMethod( payMethodId ) }}
        displayLoading={isSaveLoading}
        displayError={fetchError}
        allowSaveForFuture
      />
    )
  }

  /* --- View Current Payment Method --- */
  return (
    <ViewPayMethod
      payMethod={payMethod as IPaymentMethod}
      setUpdatePayMethod={setUpdatePayMethod}
      toggleSelect={toggleSelect}
      isSelected={isSelected}
      handleDelete={deletePaymentMethod}
      displayLoading={isDeleteLoading}
      displayError={fetchError}
    />
  )
}

export default PaymentMethod