import ImportantMessage from 'components/importantMessage'
import PhoneLink from './PhoneLink'
import FaqLink from './FaqLink'
import {authorizeNet, betterBusiness, greatPlaceToWork, inc5000} from "../../resources"

function Footer({
  billingEmail,
  billingPhone
}: { billingEmail: string, billingPhone: string }) {
  return (
    <>
      <div className="w-full">
        <FaqLink />
        <PhoneLink phoneNumber={billingPhone} />
        <ImportantMessage email={billingEmail} phoneNumber={billingPhone} />
        <div className="flex items-center justify-around my-4 pb-4">
          <div>
            <img
              src={inc5000} alt="inc 5000 magiazine image" width="53"
              height="30"
            />
          </div>
          <div>
            <img
              src={betterBusiness} alt="better business image" width="53"
              height="30"
            />
          </div>
          <div>
            <img
              src={authorizeNet} alt="authorize net image" width="53"
              height="30"
            />
          </div>
          <div>
            <img
              src={greatPlaceToWork} alt="great place to work image" width="53"
              height="30"
            />
          </div>
        </div>
        <div className="p-10 bg-aeroflow-health absolute left-0 w-screen"></div>
      </div>
    </>
  )
}

export default Footer