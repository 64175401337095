import { useState, useRef } from "react"
import { EditBankMethodProps } from "../props"
import {TextInput, CheckboxInput, SelectInput} from "../../forms/library"
import LoadingSpinner from "../../loadingSpinner"
import { useValidate } from "hooks"

export default function EditBankMethod({
  payMethod, setPayMethod, onFormSubmit, submitButtonText, onCancel, displayLoading, displayError, allowSaveForFuture
} : EditBankMethodProps ) {

  const [ confirmAccountNumber, setConfirmAccountNumber ] = useState( `` )

  const inputRefs = {
    accountNumber: useRef<HTMLInputElement>( null ),
    confirmAccountNumber: useRef<HTMLInputElement>( null ),
    routingNumber: useRef<HTMLInputElement>( null ),
    nameOnAccount: useRef<HTMLInputElement>( null ),
    accountType: useRef<HTMLSelectElement>( null )
  }

  const { evaluateErrorsOnChange,
    evaluateErrorsOnSubmit,
    evaluateErrorsOnBlur,
    formErrors : errors,
    errorsPresent,
    customInputHandlers,
    shouldShowErrors } = useValidate( inputRefs )

  const { disableDefaultAction } = customInputHandlers


  function handleInputChange( event : React.ChangeEvent<HTMLInputElement> ) {
    evaluateErrorsOnChange( event )
    const { name, value } = event.currentTarget
    if ( name === `confirmAccountNumber` ) return setConfirmAccountNumber( value )

    setPayMethod({
      ...payMethod,
      [name]: value
    })
  }

  function handleFormSubmit( event : React.FormEvent ) {
    event.preventDefault()
    const hasErrors = evaluateErrorsOnSubmit()
    if ( !hasErrors && onFormSubmit ) onFormSubmit()
  }

  function handleSavePaymentMethodChange() {
    setPayMethod({
      ...payMethod,
      shouldDeleteOnPayment: !payMethod.shouldDeleteOnPayment
    })
  }

  const inputHandlers = {
    onChange: handleInputChange,
    onBlur: evaluateErrorsOnBlur
  }

  const disableCopyPasteCutHandlers = {
    onPaste: disableDefaultAction,
    onCopy: disableDefaultAction,
    onCut: disableDefaultAction
  }

  return (
    <form onSubmit={handleFormSubmit} className="my-4 px-6 py-2 bg-gray3 rounded-lg">
      <p className="md:text-lg font-bold text-center my-4">{`Bank Account`}</p>
      <TextInput
        id="routingNumber"
        name="routingNumber"
        label="Routing number"
        type="number"
        value={payMethod.routingNumber}
        errorMessage={shouldShowErrors ? errors.routingNumber : ``}
        required
        reference={inputRefs.routingNumber}
        {...inputHandlers}
      />

      <TextInput
        id="accountNumber"
        name="accountNumber"
        label="Account number"
        type="number"
        value={payMethod.accountNumber}
        errorMessage={shouldShowErrors ? errors.accountNumber : ``}
        required
        reference={inputRefs.accountNumber}
        {...inputHandlers}
      />

      <TextInput
        id="confirmAccountNumber"
        name="confirmAccountNumber"
        label="Confirm Account number"
        type="number"
        value={confirmAccountNumber}
        errorMessage={shouldShowErrors ? errors.confirmAccountNumber : ``}
        required
        reference={inputRefs.confirmAccountNumber}
        {...inputHandlers}
        {...disableCopyPasteCutHandlers}
      />

      <SelectInput
        id="accountType"
        name="accountType"
        label="Account type"
        value={payMethod.accountType}
        errorMessage={shouldShowErrors ? errors.accountType : ``}
        required
        reference={inputRefs.accountType}
        {...inputHandlers}
      >
        <option value="checking">{`Checking`}</option>
        <option value="savings">{`Savings`}</option>
      </SelectInput>

      <TextInput
        id="nameOnAccount"
        name="nameOnAccount"
        label="Name on account"
        value={payMethod.nameOnAccount}
        errorMessage={shouldShowErrors ? errors.nameOnAccount : ``}
        required
        reference={inputRefs.nameOnAccount}
        {...inputHandlers}
      />

      <hr className="my-2"></hr>

      { displayLoading && <div className="my-2"><LoadingSpinner /></div> }

      { displayError && <p className="my-2 text-error">{displayError}</p> }



      <div className="flex flex-col items-center">

        {allowSaveForFuture && (
          <CheckboxInput
            name="saveBankAccount"
            label="Save account for future payments"
            onChange={handleSavePaymentMethodChange}
            value={!payMethod.shouldDeleteOnPayment}
            required={false}
            id="saveCardCheckbox"
          />
        )}
        <button
          type="submit"
          className="btn btn-primary w-80"
          disabled={errorsPresent || displayLoading}
        >
          {submitButtonText ? submitButtonText : `${!payMethod.shouldDeleteOnPayment ? `Save` : `Use`} Bank Account`}
        </button>

        <button
          className="btn btn-secondary w-80"
          disabled={displayLoading}
          onClick={onCancel}
        >
          {`Cancel`}
        </button>
      </div>
    </form>
  )
}