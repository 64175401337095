import React from 'react'
import {DigitalStatement} from 'types'
import {formatName} from './utils'

function Demographics({
  statementDetails
}: { statementDetails: DigitalStatement }) {

  const cardStyling = `flex flex-col items-center justify-start rounded-lg bg-white text-black py-2 px-4 w-full`
  const patientInfoStyling = `font-semibold tracking-tight text-responsive-base text-center`
  const patientDetailStyling = `text-center text-responsive-sm`
  const insuranceInfoStyling = `font-light tracking-tight text-responsive-base text-center`
  const insuranceTypeStyling = `text-primary font-bold`
  const formattedName = formatName( statementDetails.full_name )

  const AeroflowBr = () => <div className="py-0.5 bg-aeroflow-health w-full px-4 my-1"></div>

  return (
    <div className="w-full flex flex-row justify-center gap-4 sm:mt-4 md:mt-6">
      <div className="flex flex-col gap-4 w-1/3">

        <div className={cardStyling}>
          <p className={patientInfoStyling}>{`Patient`}</p>
          <AeroflowBr />
          <p className={patientDetailStyling}>{`${formattedName}`}</p>
        </div>

        <div className={cardStyling}>
          <p className={patientInfoStyling}>{`Account Number`}</p>
          <AeroflowBr />
          <p className={patientDetailStyling}>{`${statementDetails.patient_id}`}</p>
        </div>

      </div>

      <div className={cardStyling}>
        <p className={patientInfoStyling}>{`Insurance Information on File`}</p>
        <AeroflowBr />
        <div className="text-center bg-gray3 text-black rounded-lg p-3 w-full my-auto">
          {
            statementDetails?.primary_payer_name !== null &&
            <p className={insuranceInfoStyling}><span className={insuranceTypeStyling}>{`Primary `}</span>{`${statementDetails.primary_payer_name}`}</p>
          }
          {
            statementDetails?.secondary_payer_name !== null &&
            <p className={insuranceInfoStyling}><span className={insuranceTypeStyling}>{`Secondary `}</span>{`${statementDetails?.secondary_payer_name}`}</p>
          }
          {
            statementDetails?.tertiary_payer_name !== null &&
            <p className={insuranceInfoStyling}><span className={insuranceTypeStyling}>{`Tertiary `}</span>{`${statementDetails?.tertiary_payer_name}`}</p>
          }
        </div>
      </div>

    </div>
  )
}

export default Demographics