import { defaultPhoneNumber, phoneFormatter } from 'constants/constants'

function ErrorMessage() {
  const displayPhoneNumber = phoneFormatter( defaultPhoneNumber )

  return (
    <div className="flex flex-col items-center absolute top-1/3 gap-5 mx-auto">
      <h5 className="text-error text-2xl md:text-3xl uppercase">{`Oops!`}</h5>
      <p className="text-error text-lg md:text-2xl w-3/4 text-center font-light inline">
        {`There was an issue generating your statement. Please give us a call at `}
        <a className="text-error decoration-error" href={`tel:+1${defaultPhoneNumber}`}>
          <span>{displayPhoneNumber}</span>
        </a>
        {` for help.`}
      </p>
    </div>
  )
}

export default ErrorMessage