import { responsibilityMessage, paymentMessage, defaultPhoneNumber, phoneFormatter } from 'constants/constants'

function ImportantMessage({ phoneNumber, email } : { phoneNumber?: string, email: string}) {

  const defaultPhoneNumberDisplay = phoneFormatter( defaultPhoneNumber )

  return (
    <div className="bg-white text-primary w-full p-4 text-responsive-sm font-bold border rounded-lg border-primary">
      {`Important Message: `}
      <p className="inline text-responsive-sm font-light">{responsibilityMessage}</p>
      <p className="inline text-responsive-sm font-light">
        {`Please call `}
        <a className="a" href={`tel:+1${phoneNumber ? phoneNumber.replaceAll( `-`, `` ) : defaultPhoneNumber}`}>
          <span>{phoneNumber ?? defaultPhoneNumberDisplay}</span>
        </a>
        {` or email `}
        <a className="a" href={`mailto:${email}`}>
          <span>{email}</span>
        </a>
        {
          ` for questions. `
        }
      </p>
      <p className="inline text-responsive-sm font-light">{paymentMessage}</p>
    </div>
  )
}

export default ImportantMessage