import * as React from 'react'
import { Payment } from 'modules/payment'
import Logo from 'components/logo'
import Demographics from 'components/demographics/Panel'
import OrderSummary from 'components/orderSummary'
import Footer from 'components/footer'
import ErrorMessage from 'components/errorMessage'
import LoadingSpinner from 'components/loadingSpinner'
import fetchBillPaySummary from './fetchBillPaySummary'
import {useDigitalStatementStore} from "../../store"

function DigitalStatementSummary() {

  const [ error, setError ] = React.useState<boolean>( false )
  const [ loading, setLoading ] = React.useState<boolean>( false )
  const [ noBalanceOwed, setNoBalanceOwed ] = React.useState<boolean>( false )

  const {saveDigitalStatementData, digitalStatementData} = useDigitalStatementStore( ( store ) => {
    return {
      saveDigitalStatementData: store.saveDigitalStatementData,
      digitalStatementData: store.getDigitalStatementData()
    }
  })

  React.useEffect( () => {
    const controller = new AbortController
    const currentQueryParams = new URLSearchParams( window.location.search )
    const hash = currentQueryParams.get( `sgh` )

    if ( !hash ) setError( true )

    if ( hash ) {
      setLoading( true )
      fetchBillPaySummary( hash, controller.signal ).then( ( response ) => {
        if ( response?.meta?.status == `OK` && response?.data?.length > 0 && response.data[0]?.patient_id ) {
          return saveDigitalStatementData( response.data[0] )
        }
        if ( response?.meta?.status == `OK` && response?.data ) {
          return setNoBalanceOwed( true )
        }
        setError( true )
      })
        .catch( error => {
          if ( error.name != `AbortError` ) {
            setError( true )
          }
        })
        .finally( () => {
          setLoading( false )
        })
    }

    return () => {
      controller.abort()
    }
  }, [] )

  if ( loading ) {
    return ( <div className="w-screen min-h-screen flex items-center justify-center"><LoadingSpinner /></div> )
  }

  return (
    <div className="flex flex-col items-center shadow-md gap-4">
      <div className="flex flex-col justify-center items-center bg-white rounded-lg w-screen md:mb-20 max-w-7xl px-4">
        <Logo />
        {
          noBalanceOwed ?
            <p className="mt-4 text-lg">{`Your account is current and no balance is owed at this time.`}</p>
            :
            error ?
              <ErrorMessage />
              :
              Object.keys( digitalStatementData ).length > 0 ?
                <>
                  <Demographics
                    statementDetails={digitalStatementData}
                  />
                  {
                    digitalStatementData?.items?.length > 0 &&
                    <>
                      <OrderSummary
                        items={digitalStatementData.items}
                        total={digitalStatementData.total_due}
                      />
                      <Payment total={digitalStatementData.total_due?.toFixed( 2 )} />
                      <Footer
                        billingEmail={digitalStatementData.billing_email}
                        billingPhone={digitalStatementData.billing_phone}
                      />
                    </>
                  }
                </>
                :
                <LoadingSpinner />
        }
      </div>
    </div>
  )
}

export default DigitalStatementSummary