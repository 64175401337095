import Logo from "components/logo"
import { SurveyMonkey } from "components/surveyMonkey"
import { checkIco } from "assets/images"
import { usePaymentStore } from "store"

export default function ThankYouPage() {
  const { transactionId } = usePaymentStore()

  return (
    <div className="flex justify-center">
      <div className="flex flex-col items-center p-4 w-screen max-w-fit">
        <Logo />
        <h1 className="text-center font-medium sm:text-3xl">{`Payment Processed`}</h1>
        <h1 className="text-center font-medium sm:text-3xl">{`Thank You`}</h1>
        {
          transactionId &&
            <p className="mt-4 sm:text-lg text-xl">
              {`Confirmation #: `}
              <span className="font-bold">{transactionId}</span>
            </p>
        }
        <p className="mt-4 sm:text-sm">{`A receipt has been sent to your email.`}</p>
        <img
          src={checkIco}
          alt="aeroflow-checkmark-thankyou"
          className="object-contain my-6 px-4 w-2/3 md:w-5/12 lg:w-1/4"
        />
        <SurveyMonkey />
      </div>
    </div>
  )
}