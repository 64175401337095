import { getHashFromUrl } from "utils/functions"
import { reportToSentry } from "utils/reportToSentry"

export default async function fetchCustomerProfile( signal ){

  const hash = getHashFromUrl()

  if ( !hash ){
    throw new Error( `This page is not valid. If you are coming to update a credit card, please click on the link in your email inbox.` )
  }

  const response = await fetch( `${process.env.REACT_APP_AUTHNET_PAYMENTS_URL}/v2/profile`, {
    method: `GET`,
    headers: {
      "Authorization": `PatientHash ${hash}`
    },
    signal: signal
  })
    .catch( err => {

      reportToSentry( new Error( `customer profile fetch error`, {
        cause: err
      }) )
      throw new Error( `We're having trouble locating your customer profile. Please refresh the page try again. If errors persist please contact customer service at 1-800-480-5491`, {
        cause: err
      })
    })

  if ( !response.ok ) throw new Error( `response not ok` )

  return await response.json()

}