import {DigitalStatement} from 'types'
import create from 'zustand'

export interface DigitalStatementStore {
    digitalStatementData: DigitalStatement;
    saveDigitalStatementData: ( _newDigitalStatementData: DigitalStatement ) => void;
    getDigitalStatementData: () => DigitalStatement; // This is the public facing variable that will call the getter
}

const useDigitalStatementStore = create<DigitalStatementStore>()( ( set, get ) => {
  return {
    digitalStatementData: {} as DigitalStatement,
    getDigitalStatementData: () => {
      return get().digitalStatementData
    },
    saveDigitalStatementData: ( newDigitalStatementData: DigitalStatement ) => {
      return set( () => {
        return {
          digitalStatementData: {
            ...newDigitalStatementData
          }
        }
      })
    }
  }
})

export default useDigitalStatementStore