import { useNavigate, Navigate } from "react-router-dom"
import { LoadingPage } from "components/loadingSpinner"
import { useFetch } from "hooks"
import { useCustomAmountStore } from "components/payCustomAmount/store"

export default function PayPalAuthorized() {
  const search = new URLSearchParams( window.location.search )
  const sgh = search.get( `sgh` )
  const token = search.get( `token` )
  const refTransId = sessionStorage.getItem( `refTransId` )
  const navigate = useNavigate()
  const { customAmount : userDefinedTotal } = useCustomAmountStore()

  const { error } = useFetch( `${process.env.REACT_APP_AUTHNET_PAYMENTS_URL}/paypal-auth-capture-continue`, {
    method: `POST`,
    headers: {
      'Content-Type': `application/json`,
      Authorization: `PatientHash ${sgh}`
    },
    body: JSON.stringify({
      refTransId,
      token,
      amount: userDefinedTotal.replace( `$`, `` ),
      payer_id: search.get( `PayerID` )
    })
  }, {
    executeOnMount: true,
    onSuccess: ({data, errors}) => {
      const internalError = errors && errors.length > 0
      const authNetError = data?.messages?.resultCode === `Error`
      const payPalError = data?.transactionResponse?.errors?.[0]?.errorText
      if ( internalError || authNetError || payPalError?.length ) {
        if ( internalError && errors[0]?.contains( `Payment was still processed` ) ) return navigate( `/thank-you` )
        return navigate( `/paypal/0/?sgh=${sgh}&message=${encodeURIComponent( payPalError )}` )
      }
      const message = data?.transactionResponse?.messages?.[0]
      if ( message?.code === `1` || message?.description?.contains( `approved` ) ) {
        return navigate( `/thank-you` )
      }
      return navigate( `/paypal/0/?sgh=${sgh}` )
    },
    onError: ( error ) => {
      console.error( error )
      navigate( `/paypal/0/?sgh=${sgh}` )
    }
  })

  if ( error || !token || !refTransId ) return <Navigate to={`/paypal/0/sgh=?${sgh}`} replace />

  return <LoadingPage />
}