import { phone } from 'resources'
import { defaultPhoneNumber } from 'constants/constants'

function PhoneLink({ phoneNumber = defaultPhoneNumber } : { phoneNumber? : string }) {

  return (
    <a
      href={`tel:+1${phoneNumber.replaceAll( `-`, `` )}`}
      className="flex justify-center my-5 w-fit mx-auto"
    >
      <img
        src={phone}
        alt={`phone-link-image`}
        width={25}
      />
      <span className="whitespace-nowrap ml-3">{`Speak to a Representative`}</span>
    </a>
  )
}

export default PhoneLink