import { type SubscriptionDetailData } from "../types"
import { reportToSentry } from "utils/reportToSentry"

export const fetchUpdateSubscription = async ( serializePaymentMethodBody : Record<string, string>, subscriptionId: string ) => {

  const updateRecurringSubscriptionResponse = await fetch( `${process.env.REACT_APP_AUTHNET_PAYMENTS_URL}/v2/recurring-charge/${subscriptionId}`, {
    method: `PATCH`,
    headers: {
      "Authorization": `PatientHash ${getHashFromUrl()}`,
      "Content-Type": `application/json`
    },
    body: JSON.stringify({
      ...serializePaymentMethodBody
    })
  })

  return updateRecurringSubscriptionResponse.json()
}

export const fetchRestartSubscription = async ( subscriptionId : string ) => {
  const hash = getHashFromUrl()
  if ( !hash || !subscriptionId ){
    throw new Error( `This page is not valid. If you are coming to update a subscription, please click on the link in your email inbox.` )
  }

  const response = await fetch( `${process.env.REACT_APP_AUTHNET_PAYMENTS_URL}/v2/recurring-charge/restart/${subscriptionId}`, {
    method: `POST`,
    headers: {
      "Authorization": `PatientHash ${hash}`
    }
  })
    .catch( err => {
      reportToSentry( new Error( `restart subscription fetch error` ), {
        cause: err
      })
      throw new Error( `Error: Your information has been saved but we had an issue restarting your subscription. Please contact customer service at 1-800-480-5491` )
    })

  if ( !response.ok ) throw new Error( `Error: Your information has been saved but we had an issue restarting your subscription. Please contact customer service at 1-800-480-5491` )
  return await response.json()
}

export const fetchSubscriptionsDetail = async () => {
  const hash = getHashFromUrl()
  if ( !hash ) throw new Error( `This page is not valid. If you are coming to update a subscription, please click on the link in your email inbox.` )

  const response = await fetch( `${process.env.REACT_APP_PATIENTS_URL}/subscription/details?patient_hash=${hash}`, {
    headers: {
      "Authorization": `PatientHash ${hash}`
    }
  })
    .catch( err => {
      reportToSentry( new Error( `fetchSubscriptionsDetail fetch error` ), {
        cause: err
      })
      throw new Error( `We had an issue fetching your subscription(s) details. Please contact customer service at 1-800-480-5491` )
    })
  const awsResponse : AWSResponse<SubscriptionDetailData[]> = await response.json()
  if ( awsResponse?.meta?.status !== `OK` ) throw new Error( `We had an issue fetching your subscription(s) details. Please contact customer service at 1-800-480-5491` )
  return ( awsResponse ).data
}

/* Misc Helper Functions */
export function getHashFromUrl(){
  const urlParams = new URLSearchParams( window.location.search )
  return urlParams.get( `sgh` ) ?? urlParams.get( `txt` )
}

export type AWSResponse<T> = {
  data: T;
  meta: {
    status: `OK` | `ERROR`;
    message: string;
  };
}

