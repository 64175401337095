import { useState } from "react"
import { BankPaymentMethod, CardPaymentMethod, IPaymentMethod } from "types/paymentMethods"
import { fetchUpdateSubscription, fetchRestartSubscription } from "./fetchPromises"
import { reportToSentry } from "utils/reportToSentry"

export default function useUpdateSubscription() {
  const [ successMessage, setSuccessMessage ] = useState( `` )
  const [ loading, setLoading ] = useState( false )
  const [ error, setError ] = useState( `` )

  async function updateSubscription( updatedPayMethod : IPaymentMethod, subscriptionId : string ) {
    setLoading( true )
    setError( `` )

    try {
      validatePaymentMethod( updatedPayMethod )
    } catch ( err : unknown | Error ) {
      setLoading( false )
      return setError( ( err as Error )?.message ?? `There was an error updating your subscription.` )
    }

    const serializedPaymentMethod = serializeUpdatedPaymentMethod( updatedPayMethod )
    const updateSubscriptionResponse = await fetchUpdateSubscription( serializedPaymentMethod as Record<string, string>, subscriptionId )
      .catch( err => {
        setLoading( false )
        return setError( err?.message ?? `There was an error updating your subscription.` )
      })

    if ( updateSubscriptionResponse?.messages?.resultCode === `Error` ) {
      const errorMessage = updateSubscriptionResponse?.messages?.message?.[0].text ?? ``
      reportToSentry( new Error( `updateSubscriptionIssue` ), {
        errorDetails: JSON.stringify({
          subscriptionId
        })
      })
      setLoading( false )
      return setError( errorMessage || `There was an error updating your subscription. Please make sure you are connected to a network and try again.` )
    }

    return await retrySubscription( subscriptionId, true )
  }

  /* @Note: The business has request we disable this feature since this results in manual labor
  *  I will leave this here in the case we want to re-enable it in the future. The git history might be useful to see how this was implemented.
  */
  async function retrySubscription( subscriptionId : string, isUpdated = false ) {
    setLoading( true )
    setError( `` )
    // -- restart subscription, need to do this when a card on file has been updated or when a user indicates retry card
    const restartSubscriptionResponse = await fetchRestartSubscription( subscriptionId )
      .catch( ( err: unknown | Error ) => {
        setLoading( false )
        return setError( ( err as Error )?.message || `There has been an error restarting your subscription` )
      })

    setLoading( false )
    if ( restartSubscriptionResponse?.meta?.status === `Accepted` ) {
      setError( `` )
      setSuccessMessage( `Thank you! We will attempt to ${isUpdated ? `update` : `retry the payment method on file for`} this subscription. We will let you know if we run into any issues.` )
      return `success`
    } else {
      return setError( `There has been an error restarting your subscription` )
    }
  }


  /* Helper functions */
  const validatePaymentMethod = ( updatedPayMethod : IPaymentMethod ) => {
    const emptyValues = Object.values( updatedPayMethod ).some( val => typeof val === `string` && val.trim().length === 0 )
    if ( emptyValues ) {
      setLoading( false )
      throw new Error( `Please fill out all fields` )
    }
  }

  const serializeUpdatedPaymentMethod = ( paymentMethod : IPaymentMethod ) => {
    const paymentType = ( paymentMethod as CardPaymentMethod )?.cardNumber ? `card` : `ach`
    if ( paymentType === `card` ) {
      const { cardNumber, expMonth, expYear, ccv } = paymentMethod as CardPaymentMethod
      return {
        cardNumber: cardNumber?.replace( /-/g, `` ),
        expirationDate: `20${expYear}-${expMonth}`,
        cardCode: ccv
      }
    }
    /* Note: Authorize.net API is weird and likes the data to be in a certain order, so we have to serialize even if it looks like we're just spitting the data back out */
    const { routingNumber, accountNumber, accountType, nameOnAccount } = paymentMethod as BankPaymentMethod
    return {
      accountType,
      routingNumber,
      accountNumber,
      nameOnAccount
    } as BankPaymentMethod
  }



  return {
    updateSubscription,
    retrySubscription,
    loading,
    successMessage,
    setSuccessMessage,
    error,
    setError
  }
}