import {IPaymentMethod, BankPaymentMethod, CardPaymentMethod, identifyPaymentMethodType} from 'types/paymentMethods.d'

export const serializePaymentMethod = ( paymentMethod : IPaymentMethod | null ) => {
  // If we have paymentProfileId, we want the body to just be this
  if ( paymentMethod?.paymentProfileId ) return {
    paymentProfileId: paymentMethod.paymentProfileId
  }
  const paymentType = identifyPaymentMethodType( paymentMethod )
  if ( paymentType === `card` ) return serializeCardPaymentMethod( paymentMethod as CardPaymentMethod )
  if ( paymentType === `ach` ) return serializeBankPaymentMethod( paymentMethod as BankPaymentMethod )
  else throw new Error( `Invalid paymentMethod passed` )
}

export function serializeCardPaymentMethod( paymentMethod : CardPaymentMethod ) {
  return {
    cardNumber: ( paymentMethod?.cardNumber ?? `` ).replace( /-/g, `` ),
    expirationDate: `20${paymentMethod.expYear}-${paymentMethod.expMonth}`,
    cardCode: paymentMethod.ccv,
    firstName: paymentMethod.cardholderFirstName,
    lastName: paymentMethod.cardholderLastName,
    address: paymentMethod.address,
    city: paymentMethod.city,
    state: paymentMethod.state,
    zip: paymentMethod.zip
  }
}

export function serializeBankPaymentMethod( paymentMethod : BankPaymentMethod ) {
  return {
    accountType: paymentMethod.accountType,
    routingNumber: paymentMethod.routingNumber,
    accountNumber: paymentMethod.accountNumber,
    nameOnAccount: paymentMethod.nameOnAccount,
    ...( getNames( paymentMethod.nameOnAccount ) )
  }
}

export const getNames = ( fullName: string ) => {
  const names = fullName.split( ` ` )
  if ( names.length === 1 ) return {
    firstName: names[0]
  }
  if ( names.length > 1 ) return { // we have multiple names, going to take the first and add all other names to last
    firstName: names[0],
    lastName: names.slice( 1 ).join( ` ` )
  }

  return {}
}