import * as Sentry from "@sentry/react"

export const reportToSentry = ( error : Error | string, extraErrorDataObject = {}) => {
  sendSentryReport( error, extraErrorDataObject, `error` )
}

export const reportInfoToSentry = ( error : Error | string, extraErrorDataObject = {}) => {
  sendSentryReport( error, extraErrorDataObject, `info` )
}

export const reportWarningToSentry = ( error: Error | string, extraErrorDataObject = {}) => {
  sendSentryReport( error, extraErrorDataObject, `warning` )
}

export const sendSentryReport = ( error : Error | string, extraErrorDataObject = {}, level : `warning` | `info` | `error` ) => {
  Sentry.withScope( ( scope : Sentry.Scope ) => {
    scope.setLevel( level )
    scope.setContext( `Extra Details`, extraErrorDataObject )
    Sentry.captureException( ( typeof error === `string` ) ? new Error( error ) : error )
  })
}

export const getStringFromUnknownError = ( error : unknown ) : string => {
  if ( error instanceof Error ) {
    return error.message
  } else if ( typeof error === `string` ) {
    return error
  } else {
    return `Unknown error type - ${JSON.stringify( error )}`
  }
}
