async function fetchBillPaySummary(
  hash: string,
  signal: AbortSignal
) {
  const response = await fetch(
    `${process.env.REACT_APP_PATIENTS_URL}/statement?patient_hash=${hash}`,
    {
      signal: signal
    }
  )

  return await response.json()
}

export default fetchBillPaySummary